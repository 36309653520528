<template>
  <section class="small_banner" :style="`background-image: url('${image}')`" />
</template>

<script>
export default {
  name: 'BarsImage',
  props: {
    image: {
      type: String,
      default: '',
      required: true
    }
  }
}
</script>
