<template>
  <main>
    <section class="pt-16 tablet:pt-40 pb-16">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full px-4">
            <h2 class="pt-2">U wordt doorverwezen...</h2>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import { magento, token, oauth, baseUrl } from '~/service/magento'

export default {
  data() {
    return {
      orderId: 0
    }
  },
  mounted() {
    const r = {
      url: `${baseUrl}orders/${this.$route.params.pathMatch}/statuses`,
      method: 'GET'
    }
    magento
      .get(r.url, { headers: oauth.toHeader(oauth.authorize(r, token)) })
      .then((res) => {
        if (res.data === 'processing') {
          this.$router.push('/bedankt')
        } else if (
          res.data === 'canceled' ||
          res.data === 'pending' ||
          res.data === 'open'
        ) {
          this.$router.push(`/mislukt/${this.$route.params.pathMatch}`)
        } else {
          this.$toast.error(
            'Iets is er niet goed gegaan, probeer de pagina te verversen!'
          )
        }
      })
      .catch((err) => {
        this.$toast.error(
          'Iets is er niet goed gegaan, probeer de pagina te verversen!'
        )
        console.log(err)
      })
  }
}
</script>
