<template>
  <div>
    <div v-if="$parent.isHome" class="home_header">
      <header v-if="!$parent.mobile" :class="!$parent.scrolled ? '' : 'shrink'">
        <div class="container">
          <div class="flex flex-wrap">
            <div class="w-full tablet:w-1/5 pr-4">
              <div class="logo">
                <nuxt-link :to="localePath('/')"
                  ><img src="~assets/images/logo.svg" alt="MyPall"
                /></nuxt-link>
              </div>
            </div>
            <div class="w-full tablet:w-3/5 px-4">
              <ul class="main_menu">
                <li>
                  <a
                    v-t="'components.header.main_menu.shop'"
                    :href="localePath({ name: 'shop' })"
                  />
                </li>
                <li>
                  <nuxt-link
                    v-t="'components.header.main_menu.about_us'"
                    :to="localePath({ name: 'about-us' })"
                  />
                </li>
                <li>
                  <nuxt-link
                    v-t="'components.header.main_menu.contact'"
                    :to="localePath({ name: 'contact' })"
                  />
                </li>
              </ul>
            </div>
            <div class="w-full tablet:w-1/5 px-4">
              <ul class="quick_menu">
                <li>
                  <nuxt-link :to="localePath({ name: 'cart' })"
                    ><i class="fas fa-shopping-cart" /> ({{
                      amountInCart
                    }})</nuxt-link
                  >
                </li>
                <li>
                  <nuxt-link :to="localePath({ name: 'account' })"
                    ><i class="far fa-user-circle"
                  /></nuxt-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <header v-if="$parent.mobile" class="shrink">
        <div class="container">
          <div class="flex">
            <div class="w-1/4 pl-8 pt-1">
              <i class="fas fa-ellipsis-v" @click="showMenu = !showMenu" />
            </div>
            <div class="w-1/2 px-4">
              <nuxt-link :to="localePath('/')"
                ><img src="~assets/images/logo.svg" alt="MyPall"
              /></nuxt-link>
            </div>
            <div class="w-1/3 pr-2">
              <ul class="quick_menu">
                <li>
                  <nuxt-link :to="localePath({ name: 'cart' })"
                    ><i class="fas fa-shopping-cart" /> ({{
                      amountInCart
                    }})</nuxt-link
                  >
                </li>
                <li>
                  <nuxt-link :to="localePath({ name: 'account' })"
                    ><i class="far fa-user-circle"
                  /></nuxt-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div v-else class="page_header">
      <header v-if="!$parent.mobile" :class="!$parent.scrolled ? '' : 'shrink'">
        <div class="container">
          <div class="flex flex-wrap">
            <div class="w-full tablet:w-1/5 px-4">
              <div class="logo">
                <nuxt-link :to="localePath('/')"
                  ><img src="~assets/images/logo-white.svg" alt="MyPall"
                /></nuxt-link>
              </div>
            </div>
            <div class="w-full tablet:w-3/5 px-4">
              <ul class="main_menu">
                <li>
                  <a
                    v-t="'components.header.main_menu.shop'"
                    :href="localePath({ name: 'shop' })"
                  />
                </li>
                <li>
                  <nuxt-link
                    v-t="'components.header.main_menu.about_us'"
                    :to="localePath({ name: 'about-us' })"
                  />
                </li>
                <li>
                  <nuxt-link
                    v-t="'components.header.main_menu.contact'"
                    :to="localePath({ name: 'contact' })"
                  />
                </li>
              </ul>
            </div>
            <div class="w-full tablet:w-1/5 px-4">
              <ul class="quick_menu">
                <li>
                  <nuxt-link :to="localePath({ name: 'cart' })"
                    ><i class="fas fa-shopping-cart" /> ({{
                      amountInCart
                    }})</nuxt-link
                  >
                </li>
                <li>
                  <nuxt-link :to="localePath({ name: 'account' })"
                    ><i class="far fa-user-circle"
                  /></nuxt-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
      <header v-if="$parent.mobile" class="shrink">
        <div class="container">
          <div class="flex">
            <div class="w-1/4 px-4 pl-8 pt-1">
              <i class="fas fa-ellipsis-v" @click="showMenu = !showMenu" />
            </div>
            <div class="w-1/2 px-4">
              <nuxt-link :to="localePath('/')"
                ><img src="~assets/images/logo-white.svg" alt="MyPall"
              /></nuxt-link>
            </div>
            <div class="w-1/3 pr-2">
              <ul class="quick_menu">
                <li>
                  <nuxt-link :to="localePath({ name: 'cart' })"
                    ><i class="fas fa-shopping-cart" /> ({{
                      amountInCart
                    }})</nuxt-link
                  >
                </li>
                <li>
                  <nuxt-link :to="localePath({ name: 'account' })"
                    ><i class="far fa-user-circle"
                  /></nuxt-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </header>
    </div>
    <div
      v-if="showMenu && $parent.mobile"
      class="mobileMenu fixed top-0 left-0 w-screen h-screen"
      style="background: rgba(0,0,0,.5)"
    >
      <div
        class="px-8 py-4 fixed top-0 bg-white h-screen"
        style="left: 20vw; width: 80vw;"
      >
        <p class="text-right">
          <button class="text-4xl text-brown" @click="showMenu = !showMenu">
            &times;
          </button>
        </p>
        <div class="py-12">
          <ul class="mobile_menu text-black">
            <li>
              <nuxt-link
                v-t="'components.header.main_menu.home'"
                :to="localePath({ name: 'index' })"
              />
            </li>
            <li>
              <a
                v-t="'components.header.main_menu.shop'"
                :href="localePath({ name: 'shop' })"
              />
            </li>
            <li>
              <nuxt-link
                v-t="'components.header.main_menu.about_us'"
                :to="localePath({ name: 'about-us' })"
              />
            </li>
            <li>
              <nuxt-link
                v-t="'components.header.main_menu.contact'"
                :to="localePath({ name: 'contact' })"
              />
            </li>
            <li>
              <nuxt-link
                v-t="'components.header.main_menu.cart'"
                :to="localePath({ name: 'cart' })"
              />
            </li>
            <li>
              <nuxt-link
                v-t="'components.header.main_menu.account'"
                :to="localePath({ name: 'account' })"
              />
            </li>
          </ul>
        </div>
        <div class="py-4 text-center">
          <p class="my-4 text-brown">
            <i class="fab fa-facebook-f text-2xl mx-1" />
            <i class="fab fa-instagram text-2xl mx-1" />
            <i class="fab fa-pinterest-p text-2xl mx-1" />
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LayoutHeader',
  data() {
    return {
      showMenu: false
    }
  },
  computed: {
    amountInCart() {
      return this.$store.getters.amountInCart
    }
  },
  watch: {
    $route() {
      this.showMenu = false
    }
  }
}
</script>
