<template>
  <section class="text_block">
    <div class="container">
      <div class="flex flex-wrap">
        <div
          class="w-full tablet:w-1/2 image_block"
          :style="`background-image: url('${image}')`"
        />
        <div class="w-full tablet:w-1/2 px-4 pl-8 py-32 content">
          <h2
            :class="`sub_header mb-4 ${headerAlign ? headerAlign : ''}`"
            v-html="header"
          />
          <div v-if="text" class="py-4" v-html="text" />
          <nuxt-link
            v-if="button"
            :to="localePath(link)"
            class="button primary"
            v-html="button"
          />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BarsTextBlock',
  props: {
    image: {
      type: String,
      default: '',
      required: true
    },
    header: {
      type: String,
      default: '',
      required: true
    },
    text: {
      type: String,
      default: '',
      required: false
    },
    headerAlign: {
      type: String,
      default: '',
      required: false
    },
    button: {
      type: String,
      default: '',
      required: false
    },
    link: {
      type: Object,
      default: () => {},
      required: false
    }
  }
}
</script>
