<template>
  <div>

    <bars-banner
      :image="header.data.acf.image.url"
      :text="header.data.title"
      :button="header.data.acf.button_text"
      :link="{ name: header.data.acf.button_link }"
    />

    <div id="page-content" />

    <bars-product />

    <div class="walk-of-palls py-16 bg-lightBrown">
      <div class="text-center font-bold uppercase text-white text-2xl">
        {{ page.acf.walk_of_palls_wrapper.title }}
      </div>
      <div class="container mx-auto mt-8">
        <div
          class="flex flex-col tablet:flex-row tablet:justify-between mx-4 desktop:-mx-8"
        >
          <div
            v-for="image in page.acf.walk_of_palls_wrapper.images"
            v-bind:key="image.id"
            class="w-full mx-auto mb-4 tablet:mb-0 tablet:w-1/4 tablet:mx-2 desktop:mx-8"
          >
            <img :src="image.sizes.thumbnail" class="w-full" />
          </div>
        </div>
      </div>
    </div>

    <div class="four_block_with_icons py-16 bg-gray-medium">
      <div class="container mx-auto">
        <div
          class="flex flex-col tablet:flex-row tablet:justify-between mx-4 desktop:-mx-16"
        >
          <div
            v-for="content_block in page.acf.four_block_with_icon"
            v-bind:key="content_block.id"
            class="w-full mx-auto mb-4 tablet:mb-0 tablet:w-1/4 tablet:mx-2 desktop:mx-16 flex flex-col text-center"
          >
            <div
              v-if="content_block.icon"
              class="icon w-12 h-12 p-3 flex justify-center items-center bg-brown rounded-full mx-auto mb-2"
            >
              <img :src="content_block.icon" class="w-full h-full" />
            </div>
            <div class="content flex flex-col">
              <h2 v-html="content_block.title" class="text-brown uppercase font-bold text-xl" />
              <p v-html="content_block.content" class="text-brown" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BarsProduct from '../components/bars/Product'
import BarsBanner from '../components/bars/Banner'
import wordpress from '../service/wordpress'

export default {
  name: 'Homepage',
  components: {
    BarsBanner,
    BarsProduct
  },
  async asyncData() {
    let header = {}
    let textBlock = {}
    let page = await Promise.all([wordpress.get(`page/homepage`)])
    page = page[0].data
    if (page) {
      for (let i = 0; i < page.acf.bars.length; i++) {
        page.acf.bars[i].data = {}
        const barData = await Promise.all([
          wordpress.get(`banner/${page.acf.bars[i].post_name}`)
        ])
        page.acf.bars[i].data = barData[0].data
        if (page.acf.bars[i].data.acf.type === 'banner') {
          header = page.acf.bars[i]
        }
        if (page.acf.bars[i].data.acf.type === 'text-block') {
          textBlock = page.acf.bars[i]
        }
      }
    }
    return {
      page,
      header,
      textBlock
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'index',
        page_path: this.$route.fullPath
      })
    }
  },
  head() {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' - MyPall'
        : this.page.yoast.yoast_wpseo_title
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'nl_NL' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.mypall.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'MyPall' },
        {
          property: 'og:article:published_time',
          content: this.page.date
        },
        {
          property: 'og:article:modified_time',
          content: this.page.date_modified
        },
        {
          property: 'og:article:updated_time',
          content: this.page.date_modified
        },
        {
          property: 'og:image',
          content: this.page.media
            ? this.page.media.thumbnail
            : 'https://www.mypall.com/images/favicon.png'
        },
        {
          property: 'og:image:width',
          content: this.page.media ? 450 : 512
        },
        {
          property: 'og:image:height',
          content: this.page.media ? 450 : 512
        },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:description', content: description },
        { property: 'twitter:title', content: title },
        {
          property: 'twitter:image',
          content: this.page.media
            ? this.page.media.thumbnail
            : 'https://www.mypall.com/images/favicon.png'
        }
      ]
    }
  }
}
</script>
