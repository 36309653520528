var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('bars-image',{attrs:{"image":_vm.page.media['post-thumbnail']}}),_vm._v(" "),_c('section',{staticClass:"products"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"flex flex-wrap"},[_c('div',{staticClass:"w-full tablet:w-1/3 bg-white"},[_c('div',{staticClass:"product px-8 py-16"},[_c('nuxt-link',{attrs:{"to":_vm.localePath({
                  name: 'shop-product',
                  params: { product: _vm.$slugify(_vm.products[0].name) }
                })}},[_c('img',{attrs:{"src":_vm.products[0].thumbnail,"alt":_vm.products[0].name}}),_vm._v(" "),_c('h4',{staticClass:"text-center lowercase sub_header",domProps:{"innerHTML":_vm._s(_vm.products[0].name)}})])],1)]),_vm._v(" "),_c('div',{staticClass:"w-full tablet:w-1/3"},[_c('div',{staticClass:"product bg-gray-medium px-8 py-16"},[_c('nuxt-link',{attrs:{"to":_vm.localePath({
                  name: 'shop-product',
                  params: { product: _vm.$slugify(_vm.products[1].name) }
                })}},[_c('img',{attrs:{"src":_vm.products[1].thumbnail,"alt":_vm.products[1].name}}),_vm._v(" "),_c('h4',{staticClass:"text-center lowercase sub_header",domProps:{"innerHTML":_vm._s(_vm.products[1].name)}})])],1)]),_vm._v(" "),_c('div',{staticClass:"w-full tablet:w-1/3"},[_c('div',{staticClass:"product px-8 py-16"},[_c('nuxt-link',{attrs:{"to":_vm.localePath({
                  name: 'shop-product',
                  params: { product: _vm.$slugify(_vm.products[2].name) }
                })}},[_c('img',{attrs:{"src":_vm.products[2].thumbnail,"alt":_vm.products[2].name}}),_vm._v(" "),_c('h4',{staticClass:"text-center lowercase sub_header",domProps:{"innerHTML":_vm._s(_vm.products[2].name)}})])],1)])])])]),_vm._v(" "),_c('bars-slogan',{attrs:{"text":_vm.header.data.title}}),_vm._v(" "),_c('bars-about-us',{attrs:{"header":_vm.textBlock.data.title,"text":_vm.textBlock.data.acf.content}}),_vm._v(" "),_c('bars-newsletter'),_vm._v(" "),(_vm.seoText)?_c('div',{staticClass:"w-full container px-4 md:px-0"},[_c('div',{staticClass:"my-8",domProps:{"innerHTML":_vm._s(_vm.seoText.content)}})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }