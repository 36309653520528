<template>
  <div>
    <div class="top_header">
      <layout-header />
    </div>
    <main>
      <nuxt />
    </main>
    <layout-footer />
    <div
      v-if="scrolled"
      id="to_top_btn"
      v-scroll-to="'.top_header'"
      :class="
        `to-top h-10 w-10 fixed text-center ${
          overlap ? 'text-white' : 'text-brown'
        } hover:text-green uppercase cursor-pointer transition-all duration-75`
      "
    >
      <i class="fas fa-chevron-up text-xl block" /><span class="text-xl"
        >Top</span
      >
    </div>
  </div>
</template>
<script>
import LayoutHeader from '../components/layout/Header'
import LayoutFooter from '../components/layout/Footer'
export default {
  components: { LayoutFooter, LayoutHeader },
  data() {
    return {
      scrolled: false,
      mobile: false,
      overlap: false,
      isHome: this.$route.fullPath === '/'
    }
  },
  watch: {
    $route() {
      this.isHome = this.$route.fullPath === '/'
    }
  },
  mounted() {
    if (window.innerWidth <= 768) {
      this.mobile = true
    }
    window.addEventListener('scroll', this.checkScrolling)
    window.addEventListener('resize', this.checkScrolling)
  },
  destroyed() {
    window.removeEventListener('scroll', this.checkScrolling)
    window.removeEventListener('resize', this.checkScrolling)
  },
  methods: {
    checkScrolling() {
      this.scrolled = window.pageYOffset > 25
      if (document.getElementById('to_top_btn') !== null) {
        const rect1 = document
          .getElementById('to_top_btn')
          .getBoundingClientRect()
        const rect2 = document
          .getElementById('main_footer')
          .getBoundingClientRect()
        this.overlap = !(
          rect1.top > rect2.bottom ||
          rect1.right < rect2.left ||
          rect1.bottom < rect2.top ||
          rect1.left > rect2.right
        )
      }

      if (window.innerWidth <= 768) {
        this.mobile = true
      } else {
        this.mobile = false
      }
    }
  },
  head() {
    const path = this.$route.fullPath.split('/')
    const cleanPath = path.map((p) => {
      return p.replace(/-/g, ' ')
    })
    const title =
      cleanPath[cleanPath.length - 1].charAt(0).toUpperCase() +
      cleanPath[cleanPath.length - 1].substring(1)

    return {
      title: `${
        title.length > 0
          ? `${title} - ${process.env.projectTitle}`
          : process.env.projectTitle
      }`
    }
  }
}
</script>

<style>
.to-top {
  z-index: 999;
  bottom: 15px;
  right: 15px;
}
</style>
