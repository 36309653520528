<template>
  <div>
    <div class="container pt-16 tablet:pt-40 pb-16">
      <div class="flex flex-wrap">
        <section
          v-if="this.$auth.loggedIn && !creatingUser && !forgotPassword"
          id="account"
          class="w-full tablet:w-3/4 tablet:mx-auto"
        >
          <div class="barcontent">
            <h2 class="mt-0 sub_header text-center">Account</h2>
            <div class="flex flex-wrap mb-8">
              <div class="w-full tablet:w-1/2 px-4 text-center">
                <button class="button primary mt-4" @click="orderInfo = false">
                  <span>Gegevens</span>
                </button>
              </div>
              <div class="w-full tablet:w-1/2 px-4 text-center">
                <button class="button primary mt-4" @click="orderInfo = true">
                  <span>Orders</span>
                </button>
              </div>
            </div>
            <div v-if="!orderInfo" class="mt-2 mb-5">
              <div class="flex flex-wrap mt-2">
                <div class="w-full tablet:w-1/3">
                  <div class="form-group mb-2">
                    <input
                      id="firstname"
                      v-model.lazy="u.firstname"
                      type="text"
                      placeholder="Voornaam"
                      name="firstname"
                      class="form-field"
                    />
                  </div>
                </div>
                <div class="w-full tablet:w-1/3 tablet:px-4">
                  <div class="form-group mb-2">
                    <input
                      id="middlename"
                      v-model.lazy="u.middlename"
                      type="text"
                      placeholder="Tussenv."
                      name="middlename"
                      class="form-field"
                    />
                  </div>
                </div>
                <div class="w-full tablet:w-1/3">
                  <div class="form-group mb-2">
                    <input
                      id="lastname"
                      v-model.lazy="u.lastname"
                      type="text"
                      placeholder="Achternaam"
                      name="lastname"
                      class="form-field"
                    />
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap mt-2">
                <div class="w-full">
                  <div class="form-group mb-2">
                    <input
                      id="email"
                      v-model.lazy="u.email"
                      type="email"
                      placeholder="E-mailadres"
                      name="email"
                      class="form-field"
                    />
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap mt-2">
                <div class="w-full tablet:w-2/3">
                  <div class="form-group mb-2">
                    <input
                      id="postcode"
                      v-model.lazy="u.addresses[0].postcode"
                      type="text"
                      placeholder="Postcode"
                      name="postcode"
                      class="form-field"
                    />
                  </div>
                </div>
                <div class="w-full tablet:w-1/3 tablet:pl-4">
                  <div class="form-group mb-2">
                    <input
                      id="housenumber"
                      v-model.lazy="u.addresses[0].street[1]"
                      type="text"
                      placeholder="Nr."
                      name="housenumber"
                      class="form-field"
                    />
                  </div>
                </div>
              </div>
              <div class="w-full">
                <div class="form-group mb-2">
                  <input
                    id="street"
                    v-model.lazy="u.addresses[0].street[0]"
                    type="text"
                    placeholder="Straatnaam"
                    name="street"
                    class="form-field"
                  />
                </div>
              </div>
              <div class="w-full">
                <div class="form-group mb-2">
                  <input
                    id="city"
                    v-model.lazy="u.addresses[0].city"
                    type="text"
                    placeholder="Woonplaats"
                    name="city"
                    class="form-field"
                  />
                </div>
              </div>
              <div class="w-full">
                <div class="form-group mb-2">
                  <select
                    v-model="u.addresses[0].country_id"
                    class="form-field"
                  >
                    <option value="">
                      Maak een keuze
                    </option>
                    <option
                      v-for="country in countries"
                      :key="country.id"
                      :value="country.id"
                    >
                      {{ country.full_name_locale }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="flex flex-wrap mt-2">
                <div class="w-full">
                  <div class="form-group mb-2">
                    <input
                      id="telephone"
                      v-model.lazy="u.addresses[0].telephone"
                      type="tel"
                      placeholder="Telefoonnummer"
                      name="telephone"
                      class="form-field"
                    />
                  </div>
                </div>
              </div>
              <div class="flex flex-wrap">
                <div class="w-full text-right">
                  <button class="button primary" @click="updateUser">
                    <span>Update</span>
                  </button>
                </div>
              </div>

              <h3 class="mt-4 sub_header">Wachtwoord veranderen</h3>
              <div class="mt-2 mb-5">
                <div class="form-group mb-2">
                  <input
                    v-model.lazy="p.current"
                    type="password"
                    placeholder="Huidig wachtwoord"
                    name="username"
                    class="form-field"
                  />
                </div>
                <div class="form-group mb-2">
                  <input
                    v-model.lazy="p.new"
                    type="password"
                    placeholder="Nieuw wachtwoord"
                    name="firstname"
                    class="form-field"
                  />
                </div>
                <div class="flex flex-wrap">
                  <div class="w-full tablet:w-1/2 mb-4">
                    <button class="button primary" @click="logout">
                      <span>Uitloggen</span>
                    </button>
                  </div>
                  <div class="w-full tablet:w-1/2 mb-4 text-right">
                    <button class="button primary" @click="updatePassword">
                      <span>Update</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="orderInfo" class="mt-2 mb-5">
              <div v-if="orderDetail">
                <div ref="order">
                  <div class="flex flex-wrap justify-content-between">
                    <div class="w-3/4">
                      <p>
                        Bestelnummer: {{ orderDetailInfo.increment_id }}
                        <br />
                        Besteldatum: {{ orderDetailInfo.created_at }}
                        <br />
                        Betaalwijze:
                        <span
                          v-if="orderDetailInfo.payment.method === 'checkmo'"
                          >Online betaling</span
                        ><span v-else>{{
                          orderDetailInfo.payment.method
                        }}</span>
                        <br />
                        Verzendwijze: {{ orderDetailInfo.shipping_description }}
                      </p>
                    </div>
                    <div class="w-1/4">
                      <p>
                        <span style="cursor:pointer" @click="printOrder"
                          >Bestelling printen</span
                        >
                        <br />
                        Status: {{ orderDetailInfo.status }}
                      </p>
                    </div>
                  </div>
                  <div class="flex flex-wrap">
                    <div class="w-full">
                      <h6 class="sub_header">Opmerkingen:</h6>
                      <span
                        v-for="status in orderDetailInfo.status_histories"
                        :key="status.id"
                      >
                        {{ status.comment }}<br />
                      </span>
                    </div>
                  </div>
                  <hr />
                  <div class="flex flex-wrap mt-4">
                    <div class="w-1/2">
                      <h6 class="sub_header">Afleveradres</h6>
                      <span
                        >{{
                          orderDetailInfo.extension_attributes
                            .shipping_assignments[0].shipping.address.firstname
                        }}
                        {{
                          orderDetailInfo.extension_attributes
                            .shipping_assignments[0].shipping.address.middlename
                        }}
                        {{
                          orderDetailInfo.extension_attributes
                            .shipping_assignments[0].shipping.address.lastname
                        }}</span
                      ><br />
                      <span
                        >{{
                          orderDetailInfo.extension_attributes
                            .shipping_assignments[0].shipping.address.street[0]
                        }}
                        {{
                          orderDetailInfo.extension_attributes
                            .shipping_assignments[0].shipping.address.street[1]
                        }}{{
                          orderDetailInfo.extension_attributes
                            .shipping_assignments[0].shipping.address.street[2]
                        }}</span
                      ><br />
                      <span
                        >{{
                          orderDetailInfo.extension_attributes
                            .shipping_assignments[0].shipping.address.postcode
                        }},
                        {{
                          orderDetailInfo.extension_attributes
                            .shipping_assignments[0].shipping.address.city
                        }}</span
                      ><br />
                      <span>{{
                        orderDetailInfo.extension_attributes
                          .shipping_assignments[0].shipping.address.country_id
                      }}</span
                      ><br />
                      <span
                        >T:
                        {{
                          orderDetailInfo.extension_attributes
                            .shipping_assignments[0].shipping.address.telephone
                        }}</span
                      >
                    </div>
                    <div class="w-1/2">
                      <h6 class="sub_header">Factuuradres</h6>
                      <span
                        >{{ orderDetailInfo.billing_address.firstname }}
                        {{ orderDetailInfo.billing_address.middlename }}
                        {{ orderDetailInfo.billing_address.lastname }}</span
                      ><br />
                      <span
                        >{{ orderDetailInfo.billing_address.street[0] }}
                        {{ orderDetailInfo.billing_address.street[1]
                        }}{{ orderDetailInfo.billing_address.street[2] }}</span
                      ><br />
                      <span
                        >{{ orderDetailInfo.billing_address.postcode }},
                        {{ orderDetailInfo.billing_address.city }}</span
                      ><br />
                      <span>{{
                        orderDetailInfo.billing_address.country_id
                      }}</span
                      ><br />
                      <span
                        >T:
                        {{ orderDetailInfo.billing_address.telephone }}</span
                      >
                    </div>
                  </div>
                  <div class="flex flex-wrap mt-4">
                    <div class="w-full">
                      <h6 class="sub_header">Bestelde artikelen</h6>
                      <table class="table w-full">
                        <thead>
                          <tr>
                            <th scope="col">Productnaam</th>
                            <th scope="col">Prijs</th>
                            <th class="text-right" scope="col">#</th>
                            <th class="text-right" scope="col">Subtotaal</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="item in orderDetailInfo.items"
                            :key="item.id"
                          >
                            <td>{{ item.name }}</td>
                            <td>
                              €{{
                                parseFloat(item.base_price_incl_tax)
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                              }}
                            </td>
                            <td class="text-right">{{ item.qty_ordered }}</td>
                            <td class="text-right">
                              €{{
                                parseFloat(item.base_row_total_incl_tax)
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Subtotaal</td>
                            <td></td>
                            <td></td>
                            <td class="text-right">
                              €{{
                                parseFloat(orderDetailInfo.base_subtotal)
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>Verzending en afhandeling</td>
                            <td></td>
                            <td></td>
                            <td class="text-right">
                              €{{
                                parseFloat(
                                  orderDetailInfo.base_shipping_incl_tax
                                )
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td>BTW</td>
                            <td></td>
                            <td></td>
                            <td class="text-right">
                              €{{
                                parseFloat(orderDetailInfo.base_tax_amount)
                                  .toFixed(2)
                                  .toString()
                                  .replace('.', ',')
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                              }}
                            </td>
                          </tr>
                          <tr>
                            <td><strong>Eindtotaal</strong></td>
                            <td></td>
                            <td></td>
                            <td class="text-right">
                              <strong
                                >€{{
                                  parseFloat(
                                    orderDetailInfo.base_subtotal_incl_tax +
                                      orderDetailInfo.shipping_incl_tax
                                  )
                                    .toFixed(2)
                                    .toString()
                                    .replace('.', ',')
                                    .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                                }}</strong
                              >
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <button
                  class="mt-2 button primary"
                  @click="orderDetail = false"
                >
                  <span>Terug naar mijn bestellingen</span>
                </button>
              </div>
              <div v-else>
                <table class="table w-full">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col">Status</th>
                      <th scope="col">Datum</th>
                      <th class="text-right" scope="col">Prijs</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(order, index) in userOrders"
                      :key="index"
                      style="cursor:pointer"
                      @click="showOrderDetail(index)"
                    >
                      <th scope="row">{{ order.increment_id }}</th>
                      <td>{{ order.status }}</td>
                      <td>{{ order.created_at }}</td>
                      <td class="text-right">
                        €{{
                          parseFloat(
                            order.base_subtotal_incl_tax +
                              order.shipping_incl_tax
                          )
                            .toFixed(2)
                            .toString()
                            .replace('.', ',')
                            .replace(/\B(?=(\d{3})+(?!\d))/g, '.')
                        }}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </section>
        <section
          v-if="!this.$auth.loggedIn && !creatingUser && !forgotPassword"
          id="account"
          class="w-full tablet:w-3/4 tablet:mx-auto"
        >
          <div class="barcontent">
            <h2 class="mt-0 sub_header text-center">Account</h2>
            <div class="flex flex-wrap">
              <div class="w-full tablet:w-1/2 px-4">
                <h3 class="mt-4 sub_header">Inloggen</h3>
                <div class="mt-2 mb-5">
                  <div class="form-group mb-2">
                    <input
                      v-model="username"
                      type="email"
                      placeholder="E-mailadres"
                      name="username"
                      class="form-field"
                      @keydown.enter="login()"
                    />
                  </div>
                  <div class="form-group mb-2">
                    <input
                      v-model="password"
                      type="password"
                      placeholder="Wachtwoord"
                      name="password"
                      class="form-field"
                      @keydown.enter="login()"
                    />
                  </div>
                  <div class="row">
                    <div class="col-8">
                      <p>
                        <small
                          ><span
                            style="cursor:pointer"
                            @click="forgotPassword = true"
                            >Wachtwoord vergeten?</span
                          ></small
                        >
                      </p>
                    </div>
                    <div class="col-4 loginbutton text-right">
                      <button class="button primary" @click="login">
                        <span>Inloggen</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div class="w-full tablet:w-1/2 px-4">
                <h3 class="mt-5 sub_header">Nieuwe klant?</h3>
                <p class="my-4">
                  Maak je account aan! Je kunt je bestellingen volgen, je
                  betaal- en verzendgegevens aanpassen en meer!
                </p>
                <button class="button primary" @click="creatingUser = true">
                  <span>Account aanmaken</span>
                </button>
              </div>
            </div>
          </div>
        </section>
        <section
          v-if="!this.$auth.loggedIn && creatingUser && !forgotPassword"
          id="account"
          class="w-full tablet:w-3/4 tablet:mx-auto"
        >
          <div class="barcontent">
            <h2 class="mt-0 sub_header text-center">Account</h2>
            <div class="flex flex-wrap">
              <div class="w-full">
                <h3 class="mt-4">Registratie</h3>
                <div class="flex flex-wrap">
                  <div class="w-full tablet:w-1/3">
                    <div class="form-group mb-2">
                      <input
                        id="firstname"
                        v-model.lazy="c.firstname"
                        type="text"
                        placeholder="Voornaam*"
                        name="firstname"
                        class="form-field"
                      />
                    </div>
                  </div>
                  <div class="w-full tablet:w-1/3 tablet:px-4">
                    <div class="form-group mb-2">
                      <input
                        id="middlename"
                        v-model.lazy="c.middlename"
                        type="text"
                        placeholder="Tussenv."
                        name="middlename"
                        class="form-field"
                      />
                    </div>
                  </div>
                  <div class="w-full tablet:w-1/3">
                    <div class="form-group mb-2">
                      <input
                        id="lastname"
                        v-model.lazy="c.lastname"
                        type="text"
                        placeholder="Achternaam*"
                        name="lastname"
                        class="form-field"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex flex-wrap">
                  <div class="w-full">
                    <div class="form-group mb-2">
                      <input
                        id="email"
                        v-model.lazy="c.email"
                        type="email"
                        placeholder="E-mailadres*"
                        name="email"
                        class="form-field"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex flex-wrap">
                  <div class="w-full">
                    <div class="form-group mb-2">
                      <input
                        v-model.lazy="c.password"
                        type="password"
                        placeholder="Wachtwoord*"
                        name="password"
                        class="form-field"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex flex-wrap">
                  <div class="w-full">
                    <div class="form-group mb-2">
                      <input
                        v-model.lazy="c.passwordAgain"
                        type="password"
                        placeholder="Wachtwoord verificatie*"
                        name="password"
                        class="form-field"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex flex-wrap">
                  <div class="w-full tablet:w-2/3">
                    <div class="form-group mb-2">
                      <input
                        id="postcode"
                        v-model.lazy="c.postcode"
                        type="text"
                        placeholder="Postcode*"
                        name="postcode"
                        class="form-field"
                      />
                    </div>
                  </div>
                  <div class="w-full tablet:w-1/3 tablet:pl-4">
                    <div class="form-group mb-2">
                      <input
                        id="housenumber"
                        v-model.lazy="c.housenumber"
                        type="text"
                        placeholder="Nr.*"
                        name="housenumber"
                        class="form-field"
                      />
                    </div>
                  </div>
                </div>
                <div class="w-full">
                  <div class="form-group mb-2">
                    <input
                      id="street"
                      v-model.lazy="c.street"
                      type="text"
                      placeholder="Straatnaam*"
                      name="street"
                      class="form-field"
                    />
                  </div>
                </div>
                <div class="w-full">
                  <div class="form-group mb-2">
                    <input
                      id="city"
                      v-model.lazy="c.city"
                      type="text"
                      placeholder="Woonplaats*"
                      name="city"
                      class="form-field"
                    />
                  </div>
                </div>
                <div class="w-full">
                  <div class="form-group mb-2">
                    <select v-model="c.countryId" class="form-field">
                      <option value="">
                        Maak een keuze
                      </option>
                      <option
                        v-for="country in countries"
                        :key="country.id"
                        :value="country.id"
                      >
                        {{ country.full_name_locale }}
                      </option>
                    </select>
                  </div>
                </div>
                <div class="flex flex-wrap">
                  <div class="w-full">
                    <div class="form-group mb-2">
                      <input
                        id="telephone"
                        v-model.lazy="c.telephone"
                        type="tel"
                        placeholder="Telefoonnummer*"
                        name="telephone"
                        class="form-field"
                      />
                    </div>
                  </div>
                </div>
                <div class="flex flex-wrap">
                  <div class="w-1/2">
                    <button
                      class="button primary"
                      @click="creatingUser = false"
                    >
                      <span>Terug</span>
                    </button>
                  </div>
                  <div class="w-1/2 text-right">
                    <button class="button primary" @click="createUser">
                      <span>Registreren</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section
          v-if="!this.$auth.loggedIn && !creatingUser && forgotPassword"
          id="account"
          class="w-full tablet:w-1/2 tablet:mx-auto"
        >
          <div class="barcontent">
            <h2 class="mt-0 sub_header text-center">Account</h2>
            <h3 class="mt-4 sub_header">Nieuw wachtwoord opvragen</h3>
            <div class="mt-2 mb-5">
              <div class="form-group mb-2">
                <input
                  v-model.lazy="f.username"
                  type="email"
                  placeholder="E-mailadres"
                  name="username"
                  class="form-field"
                  @keydown.enter="resetPassword"
                />
              </div>
              <div class="flex flex-wrap">
                <div class="w-1/2">
                  <button
                    class="button primary"
                    @click="forgotPassword = false"
                  >
                    <span>Terug</span>
                  </button>
                </div>
                <div class="w-1/2 text-right">
                  <button class="button primary" @click="resetPassword()">
                    <span>Versturen</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import countryCodes from '~/static/vendor/js/countries.json'
import { magento, token, oauth, baseUrl } from '~/service/magento'

export default {
  data() {
    return {
      countries: countryCodes,
      creatingUser: false,
      forgotPassword: 0,
      username: '',
      password: '',
      passwordAgain: '',
      orderInfo: false,
      orderDetail: false,
      userOrders: [],
      u: {
        email: '',
        addresses: [
          {
            city: '',
            country_id: '',
            firstname: '',
            middlename: '',
            lastname: '',
            street: [],
            postcode: '',
            company: '',
            telephone: '',
            fax: ''
          }
        ]
      },
      c: {
        type: 'particulier',
        company: '',
        gender: 1,
        email: '',
        password: '',
        passwordAgain: '',
        firstname: '',
        middlename: '',
        lastname: '',
        postcode: '',
        housenumber: '',
        houseadd: '',
        street: '',
        city: '',
        countryId: '',
        telephone: '',
        fax: ''
      },
      p: {
        current: '',
        new: ''
      },
      f: {
        username: ' '
      },
      bearer: ''
    }
  },
  created() {
    if (this.$auth.loggedIn) {
      this.u = this.$auth.user
      this.bearer = this.$auth.$storage._state._token_local
    }
  },
  mounted() {
    if (this.$auth.loggedIn) {
      const r = {
        url: `${baseUrl}orders?searchCriteria[filterGroups][0][filters][0][field]=customer_email&searchCriteria[filterGroups][0][filters][0][value]=${this.$auth.user.email}`,
        method: 'GET'
      }
      magento
        .get(r.url, { headers: oauth.toHeader(oauth.authorize(r, token)) })
        .then((res) => {
          this.userOrders = res.data.items
        })
    }
  },
  methods: {
    printOrder() {
      const order = window.open('', '', 'height=1000,width=800')
      order.document.write(
        '<html><head><title>Print Order</title><link rel="stylesheet" type="text/css" href="/css/main.css"></head>'
      )
      order.document.write(this.$refs.order.innerHTML + '</html>')
      order.document.close()
      setTimeout(() => {
        order.focus()
        order.print()
        order.close()
      }, 750)
    },
    showOrderDetail(index) {
      this.orderDetail = true
      this.orderDetailInfo = this.userOrders[index]
    },
    validEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },
    validPassword(password) {
      const re = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/
      return re.test(password)
    },
    resetPassword() {
      const rData = {
        email: this.f.username,
        template: 'email_reset'
      }
      const r = { url: `${baseUrl}customers/password`, method: 'PUT' }
      magento
        .put(r.url, rData, {
          headers: oauth.toHeader(oauth.authorize(r, token))
        })
        .then((res) => {
          this.$toast.success('Reset mail met succes verstuurd!')
          console.log(res)
        })
        .catch((e) => {
          this.$toast.error('Dit mail adres bestaat niet.')
        })
    },
    updatePassword() {
      const rData = {
        currentPassword: this.p.current,
        newPassword: this.p.new
      }
      const headers = { Authorization: this.bearer }
      magento
        .put(`customers/me/password`, rData, { headers })
        .then((res) => {
          this.$toast.success('Wachtwoord met succes veranderd!')
          console.log(res)
        })
        .catch((e) => {
          this.$toast.error(
            'Kan wachtwoord niet aanpassen, er zit een fout in de wachtwoord gegevens.'
          )
          console.log(e)
        })
    },
    updateUser() {
      const rData = {
        customer: {
          email: this.u.email,
          store_id: 1,
          website_id: 1,
          suffix: this.u.addresses[0].suffix,
          gender: parseInt(this.u.gender),
          group_id: this.u.group_id,
          firstname: this.u.firstname,
          middlename: this.u.middlename,
          lastname: this.u.lastname,
          addresses: [
            {
              defaultShipping: this.u.addresses[0].default_shipping,
              defaultBilling: this.u.addresses[0].default_billing,
              firstname: this.u.firstname,
              middlename: this.u.middlename,
              lastname: this.u.lastname,
              suffix: this.u.addresses[0].suffix,
              company: this.u.addresses[0].company,
              region: this.u.addresses[0].region,
              postcode: this.u.addresses[0].postcode,
              street: this.u.addresses[0].street,
              city: this.u.addresses[0].city,
              telephone: this.u.addresses[0].telephone,
              countryId: this.u.addresses[0].country_id
            }
          ]
        }
      }
      const headers = { Authorization: this.bearer }
      magento
        .put(`customers/me`, rData, { headers })
        .then((res) => {
          this.$toast.success('Gegevens met succes veranderd!')
          console.log(res)
        })
        .catch((e) => {
          this.$toast.error(
            'Kan gegevens niet aanpassen, er zit een fout in de gegevens.'
          )
          console.log(e)
        })
    },
    async createUser() {
      try {
        if (this.validEmail(this.c.email)) {
          const rData = {
            customer_email: this.c.email,
            website_id: '1'
          }
          const r = {
            url: `${baseUrl}customers/isEmailAvailable`,
            method: 'POST'
          }
          await magento
            .post(r.url, rData, {
              headers: oauth.toHeader(oauth.authorize(r, token))
            })
            .then(async (resAvailable) => {
              if (resAvailable.data === false) {
                this.$toast.error(
                  'Er bestaat al een account met dit e-mailadres.'
                )
              } else if (this.validPassword(this.c.password)) {
                if (this.c.password === this.c.passwordAgain) {
                  const street = []
                  street.push(this.c.street)
                  street.push(this.c.housenumber)
                  street.push(this.c.houseadd)
                  const rData = {
                    customer: {
                      email: this.c.email,
                      firstname: this.c.firstname,
                      middlename: this.c.middlename,
                      lastname: this.c.lastname,
                      addresses: [
                        {
                          defaultShipping: true,
                          defaultBilling: true,
                          firstname: this.c.firstname,
                          lastname: this.c.lastname,
                          region: {
                            regionCode: '',
                            region: '',
                            regionId: 0
                          },
                          postcode: this.c.postcode,
                          street,
                          city: this.c.city,
                          telephone: this.c.telephone,
                          countryId: this.c.countryId
                        }
                      ]
                    },
                    password: this.c.password
                  }
                  const r = { url: `${baseUrl}customers`, method: 'POST' }
                  await magento
                    .post(r.url, rData, {
                      headers: oauth.toHeader(oauth.authorize(r, token))
                    })
                    .then((res) => {
                      try {
                        this.$auth
                          .loginWith('local', {
                            data: {
                              username: this.c.email,
                              password: this.c.password
                            }
                          })
                          .then((e) => {
                            if (this.$auth.loggedIn) {
                              this.$toast.success('Ingelogd!')
                              this.u = this.$auth.user

                              window.location.reload()
                            }

                            this.creatingUser = false
                          })
                          .catch((e) => {
                            this.$toast.error(
                              'Gebruikersnaam of wachtwoord is ongeldig.'
                            )
                          })
                      } catch {
                        this.$toast.error(
                          'Oeps! Er is iets fout gegaan, probeer het later nog eens.'
                        )
                      }
                    })
                    .catch((e) => {
                      this.$toast.error('Niet alle velden zijn ingevuld.')
                    })
                } else {
                  this.$toast.error('Wachtwoorden komen niet overeen.')
                }
              } else {
                this.$toast.error(
                  'Het wachtwoord vereist minstens één hoofdletter, één cijfer en 8 karakters.'
                )
              }
            })
        } else {
          this.$toast.error('Ongeldig e-mailadres.')
        }
      } catch (e) {
        this.$toast.error(
          'Oeps! Er is iets fout gegaan, probeer het later nog eens.'
        )
      }
    },
    login() {
      this.$toast.show('Inloggen...')
      try {
        this.$auth
          .loginWith('local', {
            data: {
              username: this.username,
              password: this.password
            }
          })
          .then(() => {
            if (this.$auth.loggedIn) {
              this.$toast.success('Ingelogd! Pagina wordt ververst.')
              this.u = this.$auth.user

              if (this.$auth.user.addresses.length > 0) {
                /* const rData = {
                  customer_id: this.$auth.user.id,
                  guest_quote_id: this.$store.state.cartId
                }
                /*const r = { url: `${baseUrl}mergeGuestCart`, method: 'POST' }
                magento
                  .post(r.url, rData, {
                    headers: oauth.toHeader(oauth.authorize(r, token))
                  })
                  .then(() => {
                    this.app.$cookies.remove('cartid')
                  }) */
                window.location.reload()
              }
            }
          })
          .catch(() => {
            this.$toast.error(
              'Inloggegevens zijn onjuist of uw account is tijdelijk uitgeschakeld.'
            )
          })
      } catch (e) {
        this.$toast.error(
          'Inloggegevens zijn onjuist of uw account is tijdelijk uitgeschakeld.'
        )
      }
    },
    logout() {
      try {
        this.$auth.logout()
        window.location.reload()
      } catch (e) {
        console.log(e)
      }
    }
  }
}
</script>
