<template>
  <section class="py-16">
    <div class="container">
      <div class="flex flex-wrap">
        <div class="w-full tablet:w-2/3 tablet:mx-auto px-4 text-center">
          <h2 class="sub_header" v-html="header" />
          <div class="my-4" v-html="text" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BarsAboutUs',
  props: {
    header: {
      type: String,
      default: '',
      required: true
    },
    text: {
      type: String,
      default: '',
      required: true
    }
  }
}
</script>
