<template>
  <main>
    <div class="container pt-16 tablet:pt-40 pb-16">
      <div class="flex flex-wrap">
        <section class="w-full tablet:w-1/2 tablet:mx-auto">
          <h3 class="mt-4">Een nieuw wachtwoord instellen</h3>
          <br />
          <h6 v-html="message"></h6>
          <div class="form-group mb-2">
            <input
              v-model="email"
              type="email"
              placeholder="E-mailadres *"
              name="newPass"
              class="form-field"
              @keydown.enter="newPassword()"
            />
          </div>
          <div class="form-group mb-2">
            <input
              v-model="newPass"
              type="password"
              placeholder="Nieuw wachtwoord *"
              name="newPass"
              class="form-field"
              @keydown.enter="newPassword()"
            />
          </div>
          <div class="form-group mb-2">
            <input
              v-model="newPassAgain"
              type="password"
              placeholder="Bevestig nieuw wachtwoord *"
              name="newPassAgain"
              class="form-field"
              @keydown.enter="newPassword()"
            />
            <span
              >Minimaal 8 tekens, waarvan tenminste 1 hoofdletter, 1 kleine
              letter, 1 cijfer en 1 leesteken</span
            ><br />
          </div>
          <div class="flex flex-wrap">
            <div class="w-full text-right">
              <button class="button primary" @click="newPassword()">
                <span>Nieuw wachtwoord instellen</span>
              </button>
            </div>
          </div>
        </section>
      </div>
    </div>
  </main>
</template>

<script>
import axios from 'axios'
import { magento, token, oauth, baseUrl } from '~/service/magento'

export default {
  async asyncData({ route }) {
    let email = ''
    let message = ''
    let invalid = false

    if (route.query.token) {
      await axios
        .get(
          `https://backend.mypall.com/rest/getMailFromToken/${route.query.token}`
        )
        .then((res) => {
          email = res.data

          if (res.data === '') {
            message = `<br>De wachtwoord-herstel token is verlopen of ongeldig. <br> Probeer een nieuw wachtwoord aan te vragen via de account pagina.<br><br>`
            invalid = true
          }
        })
    }

    return {
      email,
      message,
      invalid
    }
  },
  data() {
    return {
      token: '',
      newPass: '',
      newPassAgain: ''
    }
  },
  mounted() {
    this.token = this.$route.query.token
  },
  methods: {
    newPassword() {
      if (this.newPass === this.newPassAgain) {
        if (this.newPass.length >= 8) {
          const rData = {
            email: this.email,
            resetToken: this.token,
            newPassword: this.newPass
          }
          const r = { url: `${baseUrl}customers/resetPassword`, method: 'POST' }
          magento
            .post(r.url, rData, {
              headers: oauth.toHeader(oauth.authorize(r, token))
            })
            .then(() => {
              this.$router.push({ path: '/' })
              this.message = ''
            })
            .catch(() => {
              this.message =
                'Het wachtwoord dient aan de volgende eisen te voldoen: <br>Minimaal 8 karakters, waarvan minimaal 1 hoofdletter, 1 kleine letter, 1 cijfer, 1 leesteken.'
            })
        } else {
          this.message =
            'Het wachtwoord dient aan de volgende eisen te voldoen: <br>Minimaal 8 karakters, waarvan minimaal 1 hoofdletter, 1 kleine letter, 1 cijfer, 1 leesteken. <br><br>De inlognaam is hoofdlettergevoelig.'
        }
      } else {
        this.$toast.error('De wachtwoorden komen niet overeen!')
      }
    }
  }
}
</script>
