<template>
  <section id="big_banner" :style="`background-image: url('${image}')`">
    <div class="container relative h-full">
      <div
        class="flex flex-row relative h-full items-center justify-center text-center"
      >
        <div class="w-full tablet:w-3/5 px-4 md:pl-0 md:pr-4 self-center">
          <div class="caption">
            <h1
              class="sub_header text-white leading-tight mb-4"
              v-html="text"
            />
            <a
              :href="localePath(link)"
              class="button primary text-xl"
              v-html="button"
            />
          </div>
        </div>
        <div id="scroll_down">
          <button v-scroll-to="'#page-content'">
            <span id="scroll_down_span_1" class="op-1" />
            <span id="scroll_down_span_2" class="op-2" />
            <span id="scroll_down_span_3" class="op-3" />
            Scroll voor meer
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BarsBanner',
  props: {
    image: {
      type: String,
      default: '',
      required: true
    },
    text: {
      type: String,
      default: '',
      required: true
    },
    button: {
      type: String,
      default: '',
      required: true
    },
    link: {
      type: Object,
      default: () => {},
      required: true
    }
  }
}
</script>
