<template>
  <footer id="main_footer">
    <div class="container">
      <div class="flex flex-col tablet:flex-row w-full mx-4 desktop:-mx-8">
        <div class="w-3/5 tablet:w-1/4 tablet:mx-2 desktop:mx-8">
          <img src="~assets/images/logo-white.svg" alt="MyPall" />
          <p class="text-right">
            <span v-html="$t('components.footer.text')" />
          </p>
        </div>
        <div v-if="!$parent.mobile" class="w-full tablet:w-1/4 tablet:mx-2 desktop:mx-8 uppercase flex flex-col">
          <p class="font-bold">Voor de baasjes</p>
<!--          <a class="block" href="#">Welke maat</a>-->
          <a class="block" href="/klantenservice">Klantenservice</a>
          <a class="block" href="/bestel-informatie">Bestel informatie</a>
        </div>
        <div class="w-full tablet:w-1/2 tablet:mx-2 desktop:mx-8 uppercase">
          <div class="flex flex-col tablet:flex-row mx-4 desktop:-mx-8">
            <div class="w-full tablet:w-1/2 tablet:mx-2 desktop:mx-8">
              <p class="font-bold">Zakelijk</p>
              <a class="block" href="/verkooppunt-worden">Verkooppunt worden</a>
<!--              <a class="block" href="#">Downloads</a>-->
<!--              <a class="block" href="#">Lookbook</a>-->
            </div>
<!--            <div class="w-full tablet:w-1/2 tablet:mx-2 desktop:mx-8">-->
<!--              <p class="font-bold">&nbsp;</p>-->
<!--              <a class="block" href="#">Algemene voorwaarden</a>-->
<!--              <a class="block" href="#">Privacyverklaring</a>-->
<!--            </div>-->
          </div>
        </div>
      </div>
      <div class="flex flex-wrap w-full">
        <div class="w-full px-4 text-center text-sm pt-8">
          <a href="https://www.facebook.com/mypalldogs" target="_blank"><i class="fab fa-facebook-f text-2xl mx-4" /></a>
<!--          <a href="#"><i class="fab fa-pinterest-p text-2xl mx-4" /></a>-->
          <a href="https://www.instagram.com/mypall_/"><i class="fab fa-instagram text-2xl mx-4" /></a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'LayoutFooter'
}
</script>
