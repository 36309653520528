<template>
  <section id="productShowBlock" class="bg-gray-medium py-16">
    <div class="container">
      <div class="flex flex-row">
        <div class="w-full">
          <a :href="localePath({ name: 'shop' })">
            <div class="relative mx-auto image-container cursor-pointer" style="width: 820px;">
              <img
                id="productShow"
                src="~assets/images/home-product.png"
                alt="MyPall"
              />
              <transition name="fade">
                <img
                  v-if="overlayShow"
                  id="productOverlay"
                  src="~assets/images/home-product-text.png"
                  alt="MyPall"
                  class="absolute top-0 left-0"
                />
              </transition>
            </div>
          </a>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BarsProduct',
  data() {
    return {
      overlayShow: false
    }
  },
  mounted() {
    window.addEventListener('scroll', this.elementInViewport)
  },
  destroyed() {
    window.removeEventListener('scroll', this.elementInViewport)
  },
  methods: {
    elementInViewport() {
      let el = document.getElementById('productShow')
      let top = el.offsetTop
      let left = el.offsetLeft
      const width = el.offsetWidth
      const height = el.offsetHeight

      while (el.offsetParent) {
        el = el.offsetParent
        top += el.offsetTop
        left += el.offsetLeft
      }

      if (
        top >= window.pageYOffset &&
        left >= window.pageXOffset &&
        top + height <= window.pageYOffset + window.innerHeight &&
        left + width <= window.pageXOffset + window.innerWidth
      ) {
        this.overlayShow = true
      }
    }
  }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s ease-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
