<template>
  <main>
    <section class="pt-16 tablet:pt-40 pb-16">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full px-4">
            <h2 class="pt-2">Betaling mislukt!</h2>
            <p>
              Het lijkt erop dat er iets niet goed is gegaan met de betaling.<br />
              <a :href="mollieUrl">Probeer het nog eens</a> of
              <nuxt-link to="/contact">neem contact met ons</nuxt-link>.
            </p>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from 'axios'

export default {
  async asyncData({ route }) {
    const { data } = await axios.get(
      `https://backend.mypall.com/payment/createPayment.php?orderid=${route.params.pathMatch}`
    )
    return {
      mollieUrl: data
    }
  }
}
</script>
