<template>
  <div>
    <section class="pt-16 tablet:pt-40 pb-16">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full tablet:w-2/3 tablet:mx-auto px-4">
            <h2 class="sub_header mb-4" v-html="page.title" />
            <div class="my-4" v-html="page.content" />
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import wordpress from '../../service/wordpress'
export default {
  name: 'Privacy',
  async asyncData() {
    const page = await Promise.all([wordpress.get(`page/privacy`)])
    return {
      page: page[0].data
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'index',
        page_path: this.$route.fullPath
      })
    }
  },
  head() {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' - MyPall'
        : this.page.yoast.yoast_wpseo_title
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'nl_NL' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.mypall.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'MyPall' },
        {
          property: 'og:article:published_time',
          content: this.page.date
        },
        {
          property: 'og:article:modified_time',
          content: this.page.date_modified
        },
        {
          property: 'og:article:updated_time',
          content: this.page.date_modified
        },
        {
          property: 'og:image',
          content: this.page.media
            ? this.page.media.thumbnail
            : 'https://www.mypall.com/images/favicon.png'
        },
        {
          property: 'og:image:width',
          content: this.page.media ? 450 : 512
        },
        {
          property: 'og:image:height',
          content: this.page.media ? 450 : 512
        },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:description', content: description },
        { property: 'twitter:title', content: title },
        {
          property: 'twitter:image',
          content: this.page.media
            ? this.page.media.thumbnail
            : 'https://www.mypall.com/images/favicon.png'
        }
      ]
    }
  }
}
</script>
