<template>
  <div>
    <section class="pt-16 tablet:pt-40 pb-16">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full tablet:w-2/3 tablet:mx-auto px-4 text-center">
            <h2 class="sub_header mb-4" v-html="page.title" />
            <div class="my-4" v-html="page.content" />
            <div class="hidden">
              <p class="mt-8"><strong>Social Media</strong></p>
              <p class="my-4 text-brown">
                <i class="fab fa-facebook-f text-2xl mx-1" />
                <i class="fab fa-instagram text-2xl mx-1" />
                <i class="fab fa-pinterest-p text-2xl mx-1" />
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="bg-gray-light py-16">
      <div class="container">
        <form @submit.prevent="submitForm">
          <div class="flex flex-wrap">
            <div class="w-full tablet:w-1/2 px-4 mb-4">
              <label class="font-bold block mb-2" v-html="$t('form.name')" />
              <input
                v-model="name"
                type="text"
                class="form-field"
                required
                :placeholder="`${$t('form.name')} *`"
              />
            </div>
            <div class="w-full tablet:w-1/2 px-4 mb-4">
              <label class="font-bold block mb-2" v-html="$t('form.email')" />
              <input
                v-model="email"
                type="email"
                class="form-field"
                required
                :placeholder="`${$t('form.email')} *`"
              />
            </div>
            <div class="w-full px-4 mb-4">
              <label class="font-bold block mb-2" v-html="$t('form.message')" />
              <textarea
                v-model="message"
                class="form-field"
                rows="5"
                required
                :placeholder="`${$t('form.message')} *`"
              />
            </div>
            <div class="w-full px-4 mb-4 text-center">
              <button class="button primary" v-html="$t('form.send')" />
            </div>
          </div>
        </form>
      </div>
    </section>
    <bars-image :image="page.media['post-thumbnail']" />
  </div>
</template>

<script>
import BarsImage from '../../components/bars/Image'
import wordpress from '../../service/wordpress'
export default {
  name: 'Contact',
  components: { BarsImage },
  async asyncData() {
    let page = await Promise.all([wordpress.get(`page/contact`)])
    page = page[0].data
    return {
      page
    }
  },
  data() {
    return {
      name: '',
      email: '',
      message: ''
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'contact',
        page_path: this.$route.fullPath
      })
    }
  },
  methods: {
    validateEmail(email) {
      // eslint-disable-next-line no-useless-escape
      const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
    submitForm() {
      const params = new URLSearchParams()
      if (this.validateEmail(this.email)) {
        params.append('your-name', this.name)
        params.append('your-email', this.email)
        params.append('your-message', this.message)
        this.$axios
          .post(
            'https://cms.mypall.com/wp-json/contact-form-7/v1/contact-forms/5/feedback',
            params,
            {
              'Content-Type': 'application/x-www-form-urlencoded'
            }
          )
          .then(() => {
            this.message = this.$t('form.thank_you')
          })
          .catch(() => {
            this.message = this.$t('form.fail')
          })
      } else {
        this.message = this.$t('form.false_email')
      }
    }
  }
}
</script>
