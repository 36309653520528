<template>
  <section class="py-16">
    <div class="container">
      <div class="flex flex-wrap">
        <div class="w-full px-4">
          <h2 class="sub_header mb-8 text-center" v-html="header" />
        </div>
      </div>
      <div class="flex flex-wrap">
        <div class="w-full tablet:w-2/3 tablet:mx-auto">
          <div class="flex flex-wrap">
            <div class="w-full tablet:w-1/2 px-4 tablet:pr-12 pb-8">
              <p><strong v-html="usp1Header" /></p>
              <p v-html="usp1Text" />
            </div>
            <div class="w-full tablet:w-1/2 px-4 tablet:pl-12 pb-8">
              <p><strong v-html="usp3Header" /></p>
              <p v-html="usp3Text" />
            </div>
          </div>
          <div class="flex flex-wrap">
            <div class="w-full tablet:w-1/2 px-4 tablet:pr-12 pb-8">
              <p><strong v-html="usp2Header" /></p>
              <p v-html="usp2Text" />
            </div>
            <div class="w-full tablet:w-1/2 px-4 tablet:pl-12 pb-8">
              <p><strong v-html="usp4Header" /></p>
              <p v-html="usp4Text" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BarsUsps',
  props: {
    header: {
      type: String,
      default: '',
      required: true
    },
    usp1Header: {
      type: String,
      default: '',
      required: true
    },
    usp1Text: {
      type: String,
      default: '',
      required: true
    },
    usp2Header: {
      type: String,
      default: '',
      required: true
    },
    usp2Text: {
      type: String,
      default: '',
      required: true
    },
    usp3Header: {
      type: String,
      default: '',
      required: true
    },
    usp3Text: {
      type: String,
      default: '',
      required: true
    },
    usp4Header: {
      type: String,
      default: '',
      required: true
    },
    usp4Text: {
      type: String,
      default: '',
      required: true
    }
  }
}
</script>
