<template>
  <section class="bg-brown text-white py-16">
    <div class="container">
      <div class="flex flex-wrap">
        <div class="w-full tablet:w-1/2 tablet:mx-auto px-4 text-center">
          <h2 class="sub_header" v-html="text" />
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BarsSlogan',
  props: {
    text: {
      type: String,
      default: '',
      required: true
    }
  }
}
</script>
