<template>
  <div>
    <section class="pt-16 tablet:pt-40 pb-16">
      <client-only>
        <div
          v-if="cart.items.length > 0"
          class="container flex flex-wrap pt-12 h-full px-8 tablet:px-0"
        >
          <section class="w-full text-center mb-6">
            <h2 class="sub_header">
              Winkelwagen
            </h2>
          </section>

          <section id="addresses" class="w-full laptop:w-1/3 pr-5 font-bold">
            <h4 class="mb-4 sub_header">factuuradres</h4>
            <form class="flex flex-wrap" @submit.prevent="">
              <div class="w-full">
                <input
                  v-model="address.billing.firstname"
                  type="text"
                  class="form-field"
                  placeholder="voornaam *"
                />
              </div>
              <div class="w-full">
                <input
                  v-model="address.billing.middlename"
                  type="text"
                  class="form-field"
                  placeholder="tussenvoegsel"
                />
              </div>
              <div class="w-full">
                <input
                  v-model="address.billing.lastname"
                  type="text"
                  class="form-field"
                  placeholder="achternaam *"
                />
              </div>
              <div class="w-full">
                <input
                  v-model="address.billing.email"
                  type="text"
                  class="form-field"
                  placeholder="emailadres *"
                />
              </div>
              <div class="w-full">
                <input
                  v-model="address.billing.telephone"
                  type="text"
                  class="form-field"
                  placeholder="telefoonnummer *"
                />
              </div>
              <div class="w-3/4">
                <input
                  v-model="address.billing.street[0]"
                  type="text"
                  class="form-field"
                  placeholder="straat *"
                />
              </div>
              <div class="w-1/4 pl-2">
                <input
                  v-model="address.billing.street[1]"
                  type="text"
                  class="form-field"
                  placeholder="huisnr. *"
                />
              </div>
              <div class="w-1/3 pr-2">
                <input
                  v-model="address.billing.postcode"
                  type="text"
                  class="form-field"
                  placeholder="postcode *"
                />
              </div>
              <div class="w-2/3">
                <input
                  v-model="address.billing.city"
                  type="text"
                  class="form-field"
                  placeholder="woonplaats *"
                />
              </div>
            </form>

            <div class="font-medium py-4">
              <label for="ship-to-different-address" class="labelcontainer">
                <input
                  id="ship-to-different-address"
                  v-model="shipToSameAddress"
                  type="checkbox"
                />
                <span class="checkmark" />
                verzenden naar hetzelfde adres
              </label>
            </div>

            <form
              v-if="!shipToSameAddress"
              class="flex flex-wrap"
              @submit.prevent=""
            >
              <div class="w-full">
                <input
                  v-model="address.shipping.firstname"
                  type="text"
                  class="form-field"
                  placeholder="voornaam *"
                />
              </div>
              <div class="w-full">
                <input
                  v-model="address.shipping.middlename"
                  type="text"
                  class="form-field"
                  placeholder="tussenvoegsel"
                />
              </div>
              <div class="w-full">
                <input
                  v-model="address.shipping.lastname"
                  type="text"
                  class="form-field"
                  placeholder="achternaam *"
                />
              </div>
              <div class="w-full">
                <input
                  v-model="address.shipping.email"
                  type="text"
                  class="form-field"
                  placeholder="emailadres *"
                />
              </div>
              <div class="w-full">
                <input
                  v-model="address.shipping.telephone"
                  type="text"
                  class="form-field"
                  placeholder="telefoonnummer *"
                />
              </div>
              <div class="w-3/4">
                <input
                  v-model="address.shipping.street[0]"
                  type="text"
                  class="form-field"
                  placeholder="straat *"
                />
              </div>
              <div class="w-1/4 pl-2">
                <input
                  v-model="address.shipping.street[1]"
                  type="text"
                  class="form-field"
                  placeholder="huisnr. *"
                />
              </div>
              <div class="w-1/3 pr-2">
                <input
                  v-model="address.shipping.postcode"
                  type="text"
                  class="form-field"
                  placeholder="postcode *"
                />
              </div>
              <div class="w-2/3">
                <input
                  v-model="address.shipping.city"
                  type="text"
                  class="form-field"
                  placeholder="woonplaats *"
                />
              </div>
            </form>
          </section>

          <section
            id="sending-method"
            class="w-full tablet:w-1/2 laptop:w-1/3 px-5 font-bold mt-8 laptop:mt-0"
            style="border-right:1px solid #EBEBEB;border-left:1px solid #EBEBEB"
          >
            <h4 class="mb-4 sub_header">verzendmethode</h4>

            <div v-if="cart.subtotal < 350" class="flex flex-wrap">
              <div class="w-3/4">
                <label for="ship" class="labelcontainer">
                  <input
                    id="ship"
                    v-model="address.shippingMethod"
                    type="radio"
                    value="verzenden"
                  />
                  <span class="checkmark radio" />
                  <span class="font-medium">verzenden</span>
                </label>
              </div>
              <div class="w-1/4 text-right">
                <span class="text-gray">gratis</span>
              </div>
            </div>
            <div v-else class="flex flex-wrap">
              <div class="w-3/4">
                <label for="ship" class="labelcontainer">
                  <input
                    id="ship"
                    v-model="address.shippingMethod"
                    type="radio"
                    value="verzenden_350"
                  />
                  <span class="checkmark radio" />
                  <span class="font-medium">verzenden</span>
                </label>
              </div>
              <div class="w-1/4 text-right">
                <span class="text-gray">gratis</span>
              </div>
            </div>
            <div class="flex flex-wrap">
              <div class="w-3/4">
                <label for="pickup" class="labelcontainer">
                  <input
                    id="pickup"
                    v-model="address.shippingMethod"
                    type="radio"
                    value="ophalen"
                  />
                  <span class="checkmark radio" />
                  <span class="font-medium">ophalen</span>
                </label>
              </div>
              <div class="w-1/4 text-right">
                <span class="text-gray">gratis</span>
              </div>
            </div>
          </section>

          <section
            id="check-cart"
            class="w-full tablet:w-1/2 laptop:w-1/3 pl-5 font-bold mt-8 laptop:mt-0"
          >
            <h4 class="mb-4 sub_header">
              controleer uw bestelling
            </h4>
            <div class="relative">
              <div
                v-if="editingCart"
                class="absolute top left w-full h-full"
                style="background-color: rgba(255, 255, 255, .75)"
              >
                <div
                  class="absolute"
                  style="top: 50%; left: 50%; margin-top: -25px; margin-left: -25px;"
                >
                  <i
                    class="fas fa-circle-notch fa-spin text-4xl text-magenta"
                  />
                </div>
              </div>
              <div v-for="item in cartItems" :key="item.id" class="flex py-2">
                <div class="w-1/4 pr-2 flex">
                  <img
                    :src="
                      `https://webshop.mypall.com/pub/media/catalog/product/${item.product_image}`
                    "
                    alt=""
                    class="mx-auto"
                    style="width:auto;height:40px"
                  />
                </div>
                <div class="w-1/2 font-medium flex flex-wrap">
                  <div class="w-full">
                    <nuxt-link
                      :to="
                        localePath({
                          name: 'shop-product',
                          params: {
                            product: item.slug
                          }
                        })
                      "
                      v-html="item.finalName"
                    /><br />
                    <small v-if="item.color && item.maat"
                      >Kleur: {{ item.color }} | Maat: {{ item.maat }}</small
                    >
                  </div>
                  <div class="w-1/2">
                    <span v-if="item.editQty">
                      <input
                        :ref="'qty-input' + item.item_id"
                        v-model="item.qty"
                        min="1"
                        type="number"
                        style="letter-spacing: 0.1em;border-bottom: 1px solid rgb(235, 235, 235)"
                        class="w-5/6 font-medium"
                        @change="
                          () => {
                            editQty(false, item)
                            $store.dispatch('editItemQuantityInCart', item)
                          }
                        "
                        @keyup.enter="
                          () => {
                            editQty(false, item)
                            $store.dispatch('editItemQuantityInCart', item)
                          }
                        "
                      />
                    </span>
                    <span v-else> {{ item.qty }} stuk(s) </span>
                  </div>
                  <div class="w-1/2">
                    <span
                      class="cursor-pointer pr-1 fas fa-pen"
                      @click="editQty(true, item)"
                    ></span>
                    <span
                      class="cursor-pointer pl-1 fas fa-trash"
                      @click="
                        () => {
                          $store.dispatch('removeItemFromCart', item)
                        }
                      "
                    ></span>
                  </div>
                </div>
                <div class="w-1/4 pl-2 text-right">
                  <span class="text-gray">{{ item.price | formatPrice }}</span>
                </div>
              </div>
            </div>
            <div>
              <hr class="my-4" style="color:#EBEBEB" />
              <div class="flex">
                <div class="inline font-medium w-1/2">Subtotaal</div>
                <div class="inline text-right text-gray w-1/2">
                  {{ cart.subtotal | formatPrice }}
                </div>
              </div>
              <div class="flex">
                <div class="inline font-medium w-1/2">Verzendkosten</div>
                <div class="inline text-right text-gray w-1/2">
                  {{
                    loading
                      ? 'berekenen'
                      : shippingInfo.amount > 0
                      ? `€ ${shippingInfo.amount},00`
                      : 'gratis'
                  }}
                </div>
              </div>
              <div class="flex">
                <div class="inline font-medium w-1/2">Totaal</div>
                <div class="inline text-right text-gray w-1/2">
                  {{
                    (loading
                      ? cart.subtotal
                      : cart.subtotal + shippingInfo.amount) | formatPrice
                  }}
                </div>
              </div>

              <div class="py-4">
                <label
                  for="terms-and-conditions"
                  class="font-medium labelcontainer"
                >
                  <input
                    id="terms-and-conditions"
                    v-model="acceptedTermsAndConditions"
                    type="checkbox"
                  />
                  <span class="checkmark" />
                  met 'plaats bestelling' accepteer je de
                  <a :href="localePath({ name: 'conditions' })" target="_blank"
                    >voorwaarden</a
                  >
                </label>
              </div>

              <button
                :disabled="loading"
                class="button primary w-full"
                @click="
                  () =>
                    loading
                      ? ''
                      : acceptedTermsAndConditions
                      ? dispatchOrder()
                      : (message = 'De voorwaarden zijn niet geaccepteerd')
                "
              >
                {{ loading ? 'verzendkosten berekenen' : 'plaats bestelling' }}
                <i
                  v-if="ordering || loading"
                  class="fas fa-circle-notch fa-spin"
                />
              </button>

              <div
                v-if="message !== ''"
                class="font-medium pt-2"
                style="color: red"
              >
                <i class="fas fa-exclamation-circle" />
                <small>{{ message }}</small>
              </div>
              <div
                v-if="pushOrderError !== ''"
                class="font-medium pt-2"
                style="color: red"
              >
                <i class="fas fa-exclamation-circle" />
                <small>{{ pushOrderError }}</small>
              </div>
            </div>
          </section>
        </div>
        <div v-else class="container flex flex-wrap pt-12 h-full">
          <section class="w-full text-center mb-6">
            <h2 class="sub_header">
              Checkout
            </h2>
          </section>

          <section class="w-full pr-5 font-medium text-center">
            <h4 class="sub_header">De winkelwagen is leeg</h4>
          </section>
        </div>
      </client-only>
    </section>
  </div>
</template>

<script>
import { magento, token, oauth, baseUrl } from '~/service/magento'
export default {
  name: 'Cart',
  filters: {
    formatPrice(val) {
      return `€ ${val
        .toFixed(2)
        .toString()
        .replace('.', ',')
        .replace(/\B(?=(\d{3})+(?!\d))/g, '.')}`
    }
  },
  async asyncData({ store, $auth, app }) {
    const requestForCartGetTotals = {
      url: $auth.loggedIn
        ? `${baseUrl}carts/mine/totals`
        : `${baseUrl}guest-carts/${store.state.cartId}/totals`,
      method: 'GET'
    }
    const requestForCartGet = {
      url: $auth.loggedIn
        ? `${baseUrl}carts/mine`
        : `${baseUrl}guest-carts/${store.state.cartId}`,
      method: 'GET'
    }

    const [cartTotals, cart] = await Promise.all([
      magento.get(requestForCartGetTotals.url, {
        headers: $auth.loggedIn
          ? { Authorization: store.state.bearer }
          : oauth.toHeader(oauth.authorize(requestForCartGetTotals, token))
      }),
      magento.get(requestForCartGet.url, {
        headers: $auth.loggedIn
          ? { Authorization: store.state.bearer }
          : oauth.toHeader(oauth.authorize(requestForCartGet, token))
      })
    ])

    cart.data.items.map((i) => {
      i.editQty = false
      if (i.sku.includes('-')) {
        i.finalName = i.name.split('-')[0]
      } else {
        i.finalName = i.name
      }
      i.slug = app.$slugify(i.finalName)
    })

    const address = {
      shipping: JSON.parse(
        JSON.stringify(
          !$auth.loggedIn ||
            ($auth.loggedIn && $auth.user.addresses[0] === undefined)
            ? {
                firstname: '',
                middlename: '',
                lastname: '',
                street: [],
                postcode: '',
                city: '',
                country_id: 'NL',
                telephone: '',
                email: $auth.loggedIn ? $auth.user.email : ''
              }
            : {
                ...$auth.user.addresses[0],
                email: $auth.user.email,
                country_id: 'NL'
              }
        )
      ),
      billing: JSON.parse(
        JSON.stringify(
          !$auth.loggedIn ||
            ($auth.loggedIn && $auth.user.addresses[0] === undefined)
            ? {
                firstname: '',
                middlename: '',
                lastname: '',
                street: [],
                postcode: '',
                city: '',
                country_id: 'NL',
                telephone: '',
                email: $auth.loggedIn ? $auth.user.email : ''
              }
            : {
                ...$auth.user.addresses[0],
                email: $auth.user.email,
                country_id: 'NL'
              }
        )
      ),
      shippingMethod:
        cartTotals.data.subtotal < 350 ? 'verzenden' : 'verzenden_350'
    }

    store.commit('SET_CART', cartTotals.data)
    store.commit('SET_CART_ITEMS', cart.data.items)
    store.commit('SET_AMOUNT_IN_CART', cartTotals.data.items_qty)

    return {
      address
    }
  },
  data() {
    return {
      message: '',
      shipToSameAddress: true,
      acceptedTermsAndConditions: false
    }
  },
  computed: {
    pushOrderError() {
      return this.$store.getters.pushOrderError
    },
    amountInCart() {
      return this.$store.getters.amountInCart
    },
    pushedOrder() {
      return this.$store.getters.pushedOrder
    },
    loading() {
      return this.$store.getters.loadingCart
    },
    cart() {
      return this.$store.getters.cart
    },
    cartItems() {
      return this.$store.getters.cartItems
    },
    shippingInfo() {
      return this.$store.getters.shippingInfo
    },
    shippingMethod() {
      return this.address.shippingMethod
    },
    editingCart() {
      return this.$store.getters.editingCart
    },
    ordering() {
      return this.$store.getters.ordering
    }
  },
  watch: {
    address() {
      if (this.shipToSameAddress) {
        this.address.shipping = this.address.billing
      }
    },
    amountInCart() {
      if (this.shipToSameAddress) {
        this.address.shipping = this.address.billing
      }
      if (
        this.cart.subtotal < 350 &&
        this.address.shippingMethod === 'verzenden_350'
      ) {
        this.address.shippingMethod = 'verzenden'
      } else if (
        this.cart.subtotal >= 350 &&
        this.address.shippingMethod === 'verzenden'
      ) {
        this.address.shippingMethod = 'verzenden_350'
      }

      this.$store.dispatch('fetchShippingCost', this.address)
    },
    pushedOrder() {
      window.location.href = this.pushedOrder
    },
    shipToSameAddress() {
      if (this.shipToSameAddress) {
        this.address.shipping = this.address.billing
      } else {
        this.address.shipping = {
          firstname: '',
          middlename: '',
          lastname: '',
          street: [],
          postcode: '',
          city: '',
          country_id: 'NL',
          telephone: '',
          email: this.$auth.loggedIn ? this.$auth.user.email : ''
        }
      }
    },
    shippingMethod() {
      if (this.shipToSameAddress) {
        this.address.shipping = this.address.billing
      }

      this.$store.dispatch('fetchShippingCost', this.address)
    }
  },
  created() {
    this.$store.dispatch('fetchShippingCost', this.address)
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'cart',
        page_path: this.$route.fullPath
      })
    }
  },
  methods: {
    editQty(value, item) {
      item.editQty = value

      if (value) {
        setTimeout(() => {
          this.$refs['qty-input' + item.item_id][0].focus()
        }, 0)
      }
    },
    dispatchOrder() {
      if (this.shipToSameAddress) {
        this.address.shipping = this.address.billing
      }
      this.$store.dispatch('pushOrder', this.address)
      this.message = ''
    }
  }
}
</script>
