<template>
  <div>
    <section class="pt-16 tablet:pt-40 pb-16">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full tablet:w-2/3 tablet:mx-auto px-4 text-center">
            <h2 class="sub_header mb-4" v-html="page.title" />
            <div class="my-4" v-html="page.content" />
          </div>
        </div>
      </div>
    </section>
    <div class="bg-brown text-white">
      <bars-text-block
        :image="textBlock.data.acf.image.url"
        :header="textBlock.data.acf.content"
        header-align="text-center"
      />
    </div>
    <bars-usps
      :header="usps.data.title"
      :usp1-header="usps.data.acf.usp_1_header"
      :usp1-text="usps.data.acf.usp_1_text"
      :usp2-header="usps.data.acf.usp_2_header"
      :usp2-text="usps.data.acf.usp_2_text"
      :usp3-header="usps.data.acf.usp_3_header"
      :usp3-text="usps.data.acf.usp_3_text"
      :usp4-header="usps.data.acf.usp_4_header"
      :usp4-text="usps.data.acf.usp_4_text"
    />
    <bars-newsletter />
  </div>
</template>

<script>
import BarsTextBlock from '../../components/bars/TextBlock'
import wordpress from '../../service/wordpress'
import BarsUsps from '../../components/bars/Usps'
import BarsNewsletter from '../../components/bars/Newsletter'
export default {
  name: 'AboutUs',
  components: { BarsNewsletter, BarsUsps, BarsTextBlock },

  async asyncData() {
    let textBlock = {}
    let usps = {}
    let page = await Promise.all([wordpress.get(`page/over-ons`)])
    page = page[0].data
    if (page) {
      for (let i = 0; i < page.acf.bars.length; i++) {
        page.acf.bars[i].data = {}
        const barData = await Promise.all([
          wordpress.get(`banner/${page.acf.bars[i].post_name}`)
        ])
        page.acf.bars[i].data = barData[0].data
        if (page.acf.bars[i].data.acf.type === 'usps') {
          usps = page.acf.bars[i]
        }
        if (page.acf.bars[i].data.acf.type === 'text-block') {
          textBlock = page.acf.bars[i]
        }
      }
    }
    return {
      page,
      usps,
      textBlock
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'index',
        page_path: this.$route.fullPath
      })
    }
  },
  head() {
    const title =
      this.page.yoast.yoast_wpseo_title === ''
        ? this.page.title + ' - MyPall'
        : this.page.yoast.yoast_wpseo_title
    const description =
      this.page.yoast.yoast_wpseo_metadesc === ''
        ? this.page.excerpt.replace(/<(?:.|\n)*?>/gm, '')
        : this.page.yoast.yoast_wpseo_metadesc

    return {
      title,
      titleTemplate: title,
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: description
        },
        { property: 'og:locale', content: 'nl_NL' },
        { property: 'og:type', content: 'page' },
        { property: 'og:title', content: title },
        { property: 'og:description', content: description },
        {
          property: 'og:url',
          content: 'https://www.mypall.com' + this.$route.fullPath
        },
        { property: 'og:site_name', content: 'MyPall' },
        {
          property: 'og:article:published_time',
          content: this.page.date
        },
        {
          property: 'og:article:modified_time',
          content: this.page.date_modified
        },
        {
          property: 'og:article:updated_time',
          content: this.page.date_modified
        },
        {
          property: 'og:image',
          content: this.page.media
            ? this.page.media.thumbnail
            : 'https://www.mypall.com/images/favicon.png'
        },
        {
          property: 'og:image:width',
          content: this.page.media ? 450 : 512
        },
        {
          property: 'og:image:height',
          content: this.page.media ? 450 : 512
        },
        { property: 'twitter:card', content: 'summary_large_image' },
        { property: 'twitter:description', content: description },
        { property: 'twitter:title', content: title },
        {
          property: 'twitter:image',
          content: this.page.media
            ? this.page.media.thumbnail
            : 'https://www.mypall.com/images/favicon.png'
        }
      ]
    }
  }
}
</script>
