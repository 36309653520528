<template>
  <div>
    <section class="pt-16 tablet:pt-20">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full p-4">
            <p>
              <nuxt-link :to="localePath({ name: 'shop' })"
                >&lt; terug</nuxt-link
              >
            </p>
          </div>
        </div>
        <div class="flex flex-wrap">
          <div class="container">
            <div class="flex flex-wrap">
              <div class="w-full tablet:w-1/2 px-4">
                <div class="product_images bg-gray-light p-4">
                  <div v-if="activeProduct.media_gallery_entries.length > 1">
                    <client-only>
                      <agile>
                        <div
                          v-for="(image, key) in activeProduct.images"
                          :key="key"
                          @click="setIndex(key)"
                        >
                          <img
                            :src="image"
                            :alt="`${activeProduct.name}`"
                            class="cursor-pointer"
                          />
                        </div>
                        <template slot="prevButton"
                          ><i class="fas fa-chevron-left"
                        /></template>
                        <template slot="nextButton"
                          ><i class="fas fa-chevron-right"
                        /></template>
                      </agile>
                    </client-only>
                  </div>
                  <div v-else>
                    <div @click="setIndex(0)">
                      <img
                        :src="activeProduct.images[0]"
                        :alt="`${activeProduct.name}`"
                        class="cursor-pointer"
                      />
                    </div>
                  </div>
                  <client-only>
                    <gallery
                      :images="activeProduct.images"
                      :index="imageIndex"
                      @close="imageIndex = null"
                    />
                  </client-only>
                </div>
              </div>
              <div class="w-full tablet:w-1/2 px-4 tablet:pl-4 tablet:pr-0">
                <h1
                  class="sub_header lowercase text-brown leading-none"
                  v-html="activeProduct.name"
                />
                <h2 class="sub_header mb-4">
                  <span
                    v-if="configurable"
                    class="hidden"
                    v-html="'<small>vanaf </small>'"
                  />
                  <p v-html="price.toFixed(2).replace('.', ',')" />
                </h2>
                <div
                  v-if="activeProduct.short_description"
                  class="mb-12"
                  v-html="activeProduct.short_description"
                />
                <div v-if="configurable">
                  <div
                    v-for="option in activeProduct.configurable_options"
                    :key="option.id"
                    class="my-2"
                  >
                    <div>
                      <strong
                        class="uppercase"
                        v-html="option.attribute_data.default_frontend_label"
                      />
                    </div>
                    <div class="flex flex-wrap">
                      <div
                        v-for="value in option.values"
                        :key="value.value_index"
                        :class="
                          `px-2 py-1 border border-solid border-gray-dark mr-2 cursor-pointer ${
                            value.active ? 'bg-gray-light' : ''
                          }`
                        "
                        @click="
                          setOption(option.attribute_id, value.value_index)
                        "
                      >
                        <span>{{ value.label }}</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="order_form my-4">
<!--                  <input-->
<!--                    v-model="quantity"-->
<!--                    class="border border-gray-medium border-solid text-center w-24 py-1 px-3"-->
<!--                    type="number"-->
<!--                    min="1"-->
<!--                    step="1"-->
<!--                  />-->
                  <button
                    type="submit"
                    class="button primary px-8"
                    :disabled="!sellable"
                    @click="addItemToCart"
                  ><span class="px-8">In winkelmand</span></button>
                </div>
<!--                <p class="text-sm text-gray-medium my-4">SKU: #{{ sku }}</p>-->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="w-full flex flex-col bg-lightBrown text-white mt-8">
        <div v-if="activeProduct.description" class="container flex flex-wrap my-8">
          <div class="w-full px-4">
            <h3 class="sub_header">Extra informatie</h3>
            <div
                class="py-4"
                v-html="activeProduct.description"
            />
          </div>
        </div>
      </div>
    </section>
<!--    <section v-if="products.length > 0" class="pt-4 pb-16">-->
<!--      <div class="container">-->
<!--        <div class="flex flex-wrap px-4">-->
<!--          <div class="w-full px-4">-->
<!--            <h2 class="text-center sub_header mb-4">Gerelateerde producten</h2>-->
<!--          </div>-->
<!--          <div class="w-full tablet:w-1/2 tablet:mx-auto">-->
<!--            <div class="product bg-gray-medium px-8 py-4">-->
<!--              <nuxt-link-->
<!--                :to="-->
<!--                  localePath({-->
<!--                    name: 'shop-product',-->
<!--                    params: { product: $slugify(products[0].name) }-->
<!--                  })-->
<!--                "-->
<!--              >-->
<!--                <img-->
<!--                  :src="products[0].thumbnail"-->
<!--                  :alt="products[0].name"-->
<!--                  class="h-64 mx-auto -mb-8"-->
<!--                />-->
<!--                <h4-->
<!--                  class="text-center lowercase sub_header"-->
<!--                  v-html="products[0].name"-->
<!--                />-->
<!--              </nuxt-link>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </section>-->
    <section v-if="productReviews">
      <div class="container pb-12 pt-12">
        <h2 class="sub_header uppercase text-brown leading-none text-center">Reviews</h2>
        <div class="flex flex-wrap tablet:flex-row w-full mt-8">
          <div v-for="(productreview, productreviewKey) in productReviews.product_review_block" class="product-review w-full tablet:w-1/2 mb-8 flex flex-col h-full">
            <div class="image_and_overlay flex flex-col relative">
              <img :src="productreview.image" class="w-full"/>
              <div class="absolute inset-0 w-full h-full bg-black bg-opacity-50"></div>
            </div>
            <h5 class="-mt-8 text-center text-white z-10 relative">
              <span class="block -mt-16" style="text-shadow: 0 0 20px rgba(0, 0, 0, 1);" v-html="productreview.title"/>
            </h5>
            <div class="w-full p-8 bg-lightBrown text-center z-10 h-full relative">
              <p :id="'short_description_' + productreviewKey" v-html="productreview.short_description"/>
              <p :id="'long_description_' + productreviewKey" class="hidden" v-html="productreview.content"/>
              <span :id="'show_more_' + productreviewKey" @click="showMore(productreviewKey)" class="block w-full text-center pt-4 cursor-pointer hover:underline font-bold">Lees meer</span>
              <span :id="'show_less_' + productreviewKey" @click="showLess(productreviewKey)" class="block w-full text-center pt-4 cursor-pointer hover:underline font-bold hidden">Lees minder</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import wordpress from "~/service/wordpress";

export default {
  name: 'Product',
  data() {
    const products = this.$store.getters.getProducts
    const attributes = this.$store.getters.getAttributes
    const newProducts = []
    let activeProduct = {}
    for (let i = 0; i < products.length; i++) {
      if (products[i].visibility > 2) {
        products[i].custom_attributes.map((item) => {
          if (item.attribute_code === 'image') {
            products[i].thumbnail =
              'https://webshop.mypall.com/pub/media/catalog/product' +
              item.value
          }
        })
        if (this.$slugify(products[i].name) === this.$route.params.product) {
          activeProduct = products[i]
        } else {
          newProducts.push(products[i])
        }
      }
    }
    activeProduct.images = []
    activeProduct.media_gallery_entries.map((item) => {
      activeProduct.images.push(
        'https://webshop.mypall.com/pub/media/catalog/product' + item.file
      )
    })
    let price = activeProduct.price
    const sku = activeProduct.sku
    activeProduct.custom_attributes.map((item) => {
      if (item.attribute_code === 'description') {
        activeProduct.description = item.value
      }
      if (item.attribute_code === 'short_description') {
        activeProduct.short_description = item.value
      }
    })
    const configurable = activeProduct.type_id === 'configurable'
    if (configurable) {
      activeProduct.configurations = []
      let currentPrice = 0
      activeProduct.extension_attributes.configurable_product_links.map(
        (item) => {
          for (let i = 0; i < products.length; i++) {
            if (products[i].id === item) {
              if (products[i].price < currentPrice && currentPrice > 0) {
                currentPrice = products[i].price
              } else if (currentPrice === 0) {
                currentPrice = products[i].price
              }
              activeProduct.configurations.push(products[i])
            }
          }
        }
      )
      price = currentPrice
      activeProduct.configurable_options = []
      activeProduct.extension_attributes.configurable_product_options.map(
        (item) => {
          attributes.map((attribute) => {
            if (
              parseInt(item.attribute_id) === parseInt(attribute.attribute_id)
            ) {
              item.attribute_data = attribute
              for (let i = 0; i < item.values.length; i++) {
                for (let j = 0; j < attribute.options.length; j++) {
                  if (
                    parseInt(item.values[i].value_index) ===
                    parseInt(attribute.options[j].value)
                  ) {
                    item.values[i].label = attribute.options[j].label
                    item.values[i].active = false
                  }
                }
              }
            }
          })
          activeProduct.configurable_options.push(item)
        }
      )
    }
    return {
      products: newProducts,
      activeProduct,
      price,
      sku,
      configurable,
      sellable: !configurable,
      quantity: 1,
      imageIndex: null,
      selectedConfiguration: null,
      productReviews: null,
      reviewOpened: false,
    }
  },
  created() {
    wordpress
        .get(`post/123`)
        .then(response => this.productReviews = response.data.acf);
  },
  computed: {
    successCartMessage() {
      return this.$store.getters.successCartMessage
    },
    editingCartMessage() {
      return this.$store.getters.editingCartMessage
    },
    editingCart() {
      return this.$store.getters.editingCart
    }
  },
  watch: {
    successCartMessage() {
      if (this.successCartMessage !== '') {
        this.$toast.success('Product is toegevoegd')
      }
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'product',
        page_path: this.$route.fullPath
      })
    }
  },
  methods: {
    showMore(key) {
      document.getElementById('long_description_' + key).classList.remove('hidden');
      document.getElementById('short_description_' + key).classList.add('hidden');
      document.getElementById('show_more_' + key).classList.add('hidden');
      document.getElementById('show_less_' + key).classList.remove('hidden');
    },
    showLess(key) {
      document.getElementById('long_description_' + key).classList.add('hidden');
      document.getElementById('short_description_' + key).classList.remove('hidden');
      document.getElementById('show_more_' + key).classList.remove('hidden');
      document.getElementById('show_less_' + key).classList.add('hidden');
    },
    addItemToCart() {
      this.$toast.info('Product wordt toegevoegd')
      const product = this.configurable
        ? this.selectedConfiguration
        : this.activeProduct
      product.qty = this.quantity
      if (this.configurable) {
        const customOptions = []
        this.activeProduct.configurable_options.map((item) => {
          item.values.map((value) => {
            if (value.active) {
              customOptions.push({
                option_id: item.attribute_id,
                option_value: value.value_index
              })
            }
          })
        })
        product.qty = this.quantity
        product.product_option = {}
        product.product_option.extension_attributes = {}
        product.product_option.extension_attributes.custom_options = customOptions
      }
      this.$store.dispatch('addItemToCart', product)
    },
    setIndex(index) {
      this.imageIndex = index
    },
    setOption(option, value) {
      this.activeProduct.configurable_options.map((item) => {
        if (item.attribute_id === option) {
          item.values.map((valueItem) => {
            if (valueItem.value_index === value) {
              valueItem.active = true
            } else {
              valueItem.active = false
            }
          })
        }
      })
      let selectedOptions = 0
      this.activeProduct.configurable_options.map((item) => {
        item.values.map((valueItem) => {
          if (valueItem.active) {
            selectedOptions++
          }
        })
      })
      if (selectedOptions === this.activeProduct.configurable_options.length) {
        this.activeProduct.configurations.map((product) => {
          let matchingProduct = 0
          for (
            let i = 0;
            i < this.activeProduct.configurable_options.length;
            i++
          ) {
            for (let j = 0; j < product.custom_attributes.length; j++) {
              if (
                this.activeProduct.configurable_options[i].attribute_data
                  .attribute_code ===
                product.custom_attributes[j].attribute_code
              ) {
                this.activeProduct.configurable_options.map((item) => {
                  item.values.map((valueItem) => {
                    if (valueItem.active) {
                      if (
                        parseInt(valueItem.value_index) ===
                        parseInt(product.custom_attributes[j].value)
                      ) {
                        matchingProduct++
                      }
                    }
                  })
                })
              }
            }
          }
          if (matchingProduct === selectedOptions) {
            this.selectedConfiguration = product
            this.price = product.price
            this.sku = product.sku
          }
        })
        this.sellable = true
      }
    }
  }
}
</script>

<style>
.blueimp-gallery,
.blueimp-gallery > .slides > .slide > .slide-content {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  -webkit-transition: opacity 0.2s linear;
  -moz-transition: opacity 0.2s linear;
  -ms-transition: opacity 0.2s linear;
  -o-transition: opacity 0.2s linear;
  transition: opacity 0.2s linear;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
.blueimp-gallery > .slides > .slide > .slide-content {
  margin: auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  opacity: 1;
}
.blueimp-gallery {
  position: fixed;
  z-index: 999999;
  overflow: hidden;
  background: #fff;
  opacity: 0;
  display: none;
  direction: ltr;
  -ms-touch-action: none;
  touch-action: none;
}
.blueimp-gallery-carousel {
  position: relative;
  z-index: auto;
  margin: 1em auto;
  padding-bottom: 56.25%;
  box-shadow: 0 0 10px #000;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.blueimp-gallery-display {
  display: block;
  opacity: 1;
}
.blueimp-gallery > .slides {
  position: relative;
  height: 100%;
  overflow: hidden;
}
.blueimp-gallery-carousel > .slides {
  position: absolute;
}
.blueimp-gallery > .slides > .slide {
  position: relative;
  float: left;
  height: 100%;
  text-align: center;
  -webkit-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -moz-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -ms-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  -o-transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
}
.blueimp-gallery > .slides > .slide-loading {
  /*background: url(../img/loading.gif) center no-repeat;*/
  background-size: 64px 64px;
}
.blueimp-gallery > .slides > .slide-loading > .slide-content {
  opacity: 0;
}
.blueimp-gallery > .slides > .slide-error {
  /*background: url(../img/error.png) center no-repeat;*/
}
.blueimp-gallery > .slides > .slide-error > .slide-content {
  display: none;
}
.blueimp-gallery > .next,
.blueimp-gallery > .prev {
  position: absolute;
  top: 50%;
  left: 15px;
  width: 40px;
  height: 40px;
  margin-top: -23px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 60px;
  font-weight: 100;
  line-height: 30px;
  color: #586666;
  text-decoration: none;
  text-align: center;
  background: #fff;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: 3px solid #586666;
  -webkit-border-radius: 23px;
  -moz-border-radius: 23px;
  border-radius: 23px;
  opacity: 0.5;
  cursor: pointer;
  display: none;
}
.blueimp-gallery > .next {
  left: auto;
  right: 15px;
}
.blueimp-gallery > .close,
.blueimp-gallery > .title {
  position: absolute;
  top: 15px;
  left: 15px;
  margin: 0 40px 0 0;
  font-size: 20px;
  line-height: 30px;
  color: #586666;
  opacity: 0.8;
  display: none;
}
.blueimp-gallery > .close {
  padding: 15px;
  right: 15px;
  left: auto;
  margin: -15px;
  font-size: 80px;
  text-decoration: none;
  cursor: pointer;
}
.blueimp-gallery > .play-pause {
  position: absolute;
  right: 15px;
  bottom: 15px;
  width: 15px;
  height: 15px;
  /*background: url(../img/play-pause.png) 0 0 no-repeat;*/
  cursor: pointer;
  opacity: 0.5;
  display: none;
}
.blueimp-gallery-playing > .play-pause {
  background-position: -15px 0;
}
.blueimp-gallery-controls > .close,
.blueimp-gallery-controls > .next,
.blueimp-gallery-controls > .play-pause,
.blueimp-gallery-controls > .prev,
.blueimp-gallery-controls > .title {
  display: block;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.blueimp-gallery-left > .prev,
.blueimp-gallery-right > .next,
.blueimp-gallery-single > .next,
.blueimp-gallery-single > .play-pause,
.blueimp-gallery-single > .prev {
  display: none;
}
.blueimp-gallery > .close,
.blueimp-gallery > .next,
.blueimp-gallery > .play-pause,
.blueimp-gallery > .prev,
.blueimp-gallery > .slides > .slide > .slide-content {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.blueimp-gallery > .close:hover,
.blueimp-gallery > .next:hover,
.blueimp-gallery > .play-pause:hover,
.blueimp-gallery > .prev:hover,
.blueimp-gallery > .title:hover {
  color: #68715b;
  opacity: 1;
}
body:last-child .blueimp-gallery > .slides > .slide-error {
  /*background-image: url(../img/error.svg);*/
}
body:last-child .blueimp-gallery > .play-pause {
  width: 20px;
  height: 20px;
  background-size: 40px 20px;
  /*background-image: url(../img/play-pause.svg);*/
}
body:last-child .blueimp-gallery-playing > .play-pause {
  background-position: -20px 0;
}
* + html .blueimp-gallery > .slides > .slide {
  min-height: 300px;
}
* + html .blueimp-gallery > .slides > .slide > .slide-content {
  position: relative;
}
.blueimp-gallery > .indicator {
  position: absolute;
  top: auto;
  right: 15px;
  bottom: 15px;
  left: 15px;
  margin: 0 40px;
  padding: 0;
  list-style: none;
  text-align: center;
  line-height: 10px;
  display: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.blueimp-gallery > .indicator > li {
  display: inline-block;
  width: 9px;
  height: 9px;
  margin: 6px 3px 0 3px;
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  border: 1px solid transparent;
  background: #ccc;
  background: rgba(255, 255, 255, 0.25) center no-repeat;
  border-radius: 5px;
  box-shadow: 0 0 2px #000;
  opacity: 0.5;
  cursor: pointer;
}
* + html .blueimp-gallery > .indicator > li {
  display: inline;
}
.blueimp-gallery > .indicator > .active,
.blueimp-gallery > .indicator > li:hover {
  background-color: #fff;
  border-color: #fff;
  opacity: 1;
}
.blueimp-gallery > .indicator > li:after {
  opacity: 0;
  display: block;
  position: absolute;
  content: '';
  top: -5em;
  width: 75px;
  height: 75px;
  transition: transform 0.6s ease-out, opacity 0.4s ease-out;
  transform: translateX(-50%) translateY(0) translateZ(0);
  pointer-events: none;
}
.blueimp-gallery > .indicator > li:hover:after {
  opacity: 1;
  border-radius: 50%;
  background: inherit;
  transform: translateX(-50%) translateY(-5px) translateZ(0);
}
.blueimp-gallery > .indicator > .active:after {
  display: none;
}
.blueimp-gallery-controls > .indicator {
  display: block;
  -webkit-transform: translateZ(0);
  -moz-transform: translateZ(0);
  -ms-transform: translateZ(0);
  -o-transform: translateZ(0);
  transform: translateZ(0);
}
.blueimp-gallery-single > .indicator {
  display: none;
}
.blueimp-gallery > .slides > .slide > .video-content > img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
  backface-visibility: hidden;
  -moz-backface-visibility: hidden;
}
.blueimp-gallery > .slides > .slide > .video-content > video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
}
.blueimp-gallery > .slides > .slide > .video-content > iframe {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  height: 100%;
  border: none;
}
.blueimp-gallery > .slides > .slide > .video-playing > iframe {
  top: 0;
}
.blueimp-gallery > .slides > .slide > .video-content > a {
  position: absolute;
  top: 50%;
  right: 0;
  left: 0;
  margin: -64px auto 0;
  width: 128px;
  height: 128px;
  /*background: url(../img/video-play.png) center no-repeat;*/
  opacity: 0.8;
  cursor: pointer;
}
.blueimp-gallery > .slides > .slide > .video-playing > a,
.blueimp-gallery > .slides > .slide > .video-playing > img {
  display: none;
}
.blueimp-gallery > .slides > .slide > .video-playing > video {
  display: block;
}
.blueimp-gallery > .slides > .slide > .video-loading > a {
  /*background: url(../img/loading.gif) center no-repeat;*/
  background-size: 64px 64px;
}
* + html .blueimp-gallery > .slides > .slide > .video-content {
  height: 100%;
}
* + html .blueimp-gallery > .slides > .slide > .video-content > a {
  left: 50%;
  margin-left: -64px;
}
.blueimp-gallery > .slides > .slide > .video-content > a:hover {
  opacity: 1;
}
body:last-child
  .blueimp-gallery
  > .slides
  > .slide
  > .video-content:not(.video-loading)
  > a {
  /*background-image: url(../img/video-play.svg);*/
}
.agile {
  position: relative;
}
.agile--ssr .agile__slides--cloned {
  display: none;
}
.agile--ssr .agile__slides > * {
  overflow: hidden;
  width: 0;
}
.agile--ssr .agile__slides > :first-child {
  width: 100%;
}
.agile--rtl .agile__actions,
.agile--rtl .agile__dots,
.agile--rtl .agile__slides,
.agile--rtl .agile__track {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: reverse;
  -ms-flex-direction: row-reverse;
  flex-direction: row-reverse;
}
.agile:active,
.agile :active,
.agile:focus,
.agile :focus {
  outline: none;
}
.agile__list {
  display: block;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.agile__track {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
}
.agile__actions,
.agile__track {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.agile__actions {
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  height: 0;
}
.agile--no-nav-buttons .agile__actions {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.agile__slides {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: unset;
  flex-shrink: unset;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.agile--disabled .agile__slides {
  display: block;
  width: 100%;
}
.agile__slide {
  display: block;
  -webkit-box-flex: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-negative: 0;
  flex-shrink: 0;
}
.agile__slide,
.agile__slide * {
  -webkit-user-drag: none;
}
.agile--fade .agile__slide {
  opacity: 0;
  position: relative;
  z-index: 0;
}
.agile--fade .agile__slide--active {
  opacity: 1;
  z-index: 2;
}
.agile--fade .agile__slide--expiring {
  opacity: 1;
  -webkit-transition-duration: 0s;
  transition-duration: 0s;
  z-index: 1;
}
.agile__nav-button[disabled] {
  cursor: default;
}
.agile__nav-button {
  position: relative;
  margin-top: -65%;
  padding-left: 5px;
  padding-right: 5px;
  font-size: 24px;
  cursor: pointer;
  border: none;
  background: none;
  color: #ccc;
  transition: all 0.3s;
}
.agile__nav-button:hover {
  color: #888;
}
.agile__dots {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style: none;
  padding: 0;
  white-space: nowrap;
  margin-top: -20px;
  position: relative;
}
.agile__dot button {
  background-color: #dcdeda;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  display: block;
  height: 15px;
  font-size: 0;
  line-height: 0;
  margin: 0 2.5px;
  padding: 0;
  -webkit-transition-duration: 0.3s;
  transition-duration: 0.3s;
  width: 15px;
}
.agile__dot--current button,
.agile__dot:hover button {
  background-color: #746c54;
}
</style>
