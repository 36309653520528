<template>
  <section class="bg-gray-light py-16">
    <div class="flex flex-wrap">
      <div class="w-full px-4 text-center">
        <h3 class="sub_header" v-html="'Heb je een vraag?'" />
        <p class="mt-6">
          <nuxt-link
            :to="localePath({ name: 'contact' })"
            class="button primary"
            >neem contact op</nuxt-link
          >
        </p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'BarsNewsletter'
}
</script>
