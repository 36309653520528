<template>
  <div>
    <bars-image :image="page.media['post-thumbnail']" />
    <section class="products">
      <div class="container">
        <div class="flex flex-wrap">
          <div class="w-full tablet:w-1/3 bg-white">
            <div class="product px-8 py-16">
              <nuxt-link
                :to="
                  localePath({
                    name: 'shop-product',
                    params: { product: $slugify(products[0].name) }
                  })
                "
              >
                <img :src="products[0].thumbnail" :alt="products[0].name" />
                <h4
                  class="text-center lowercase sub_header"
                  v-html="products[0].name"
                />
              </nuxt-link>
            </div>
          </div>
          <div class="w-full tablet:w-1/3">
            <div class="product bg-gray-medium px-8 py-16">
              <nuxt-link
                :to="
                  localePath({
                    name: 'shop-product',
                    params: { product: $slugify(products[1].name) }
                  })
                "
              >
                <img :src="products[1].thumbnail" :alt="products[1].name" />
                <h4
                  class="text-center lowercase sub_header"
                  v-html="products[1].name"
                />
              </nuxt-link>
            </div>
          </div>
          <div class="w-full tablet:w-1/3">
            <div class="product px-8 py-16">
              <nuxt-link
                  :to="
                  localePath({
                    name: 'shop-product',
                    params: { product: $slugify(products[2].name) }
                  })
                "
              >
                <img :src="products[2].thumbnail" :alt="products[2].name" />
                <h4
                    class="text-center lowercase sub_header"
                    v-html="products[2].name"
                />
              </nuxt-link>
            </div>
          </div>
        </div>
      </div>
    </section>
    <bars-slogan :text="header.data.title" />
    <bars-about-us
      :header="textBlock.data.title"
      :text="textBlock.data.acf.content"
    />
    <bars-newsletter />
    <div v-if="seoText" class="w-full container px-4 md:px-0">
      <div class="my-8" v-html="seoText.content" />
    </div>
  </div>
</template>

<script>
import BarsImage from '../../components/bars/Image'
import BarsSlogan from '../../components/bars/Slogan'
import BarsAboutUs from '../../components/bars/AboutUs'
import wordpress from '../../service/wordpress'
import BarsNewsletter from '../../components/bars/Newsletter'
export default {
  name: 'Shop',
  components: { BarsNewsletter, BarsAboutUs, BarsSlogan, BarsImage },
  async asyncData() {
    let header = {}
    let textBlock = {}
    let page = await Promise.all([wordpress.get(`page/shop`)])
    page = page[0].data
    if (page) {
      for (let i = 0; i < page.acf.bars.length; i++) {
        page.acf.bars[i].data = {}
        const barData = await Promise.all([
          wordpress.get(`banner/${page.acf.bars[i].post_name}`)
        ])
        page.acf.bars[i].data = barData[0].data
        if (
          page.acf.bars[i].data.acf.type === 'text-block' &&
          page.acf.bars[i].data.slug ===
            'you-give-love-youll-get-it-back-in-tenfold'
        ) {
          header = page.acf.bars[i]
        }
        if (
          page.acf.bars[i].data.acf.type === 'text-block' &&
          page.acf.bars[i].data.slug === '100-dutch-design'
        ) {
          textBlock = page.acf.bars[i]
        }
      }
    }
    return {
      page,
      header,
      textBlock
    }
  },
  data() {
    const products = this.$store.getters.getProducts
    const newProducts = []
    for (let i = 0; i < products.length; i++) {
      if (products[i].visibility > 2) {
        products[i].custom_attributes.map((item) => {
          if (item.attribute_code === 'image') {
            products[i].thumbnail =
              'https://webshop.mypall.com/pub/media/catalog/product' +
              item.value
          }
        })
        newProducts.push(products[i])
      }
    }

    return {
      products: newProducts,
      seoText: null
    }
  },
  mounted() {
    if (process.browser) {
      this.$gtag('event', 'your_event', {
        page_title: 'shop',
        page_path: this.$route.fullPath
      })
    }
  },
  async created() {
    let currentSeoText = await Promise.all([wordpress.get(`post/162`)]);
    this.seoText = currentSeoText[0].data;
  }
}
</script>
